import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { Observable, of } from 'rxjs';
import { Workflow } from '../models/workflow.model';
import { SessionService } from './session.service';
import { Group } from '../models/group.model';
import { Role, RoleKey } from '../models/role.model';
import { LandingPageConfigs } from '../models/landing-page-configs.model';
import _ from 'lodash-es';
import { Util } from '../util';
import { ContactInfo } from '../models/contact-info.model';
import { Document } from '../models/document.model';
import { FilingType } from '../models/filing-type.model';
import { WorkflowType } from '../models/workflow-type.model';
import { BaseGroup } from '../models/base-group.model';
import { FilingTypeKey } from '../models/filing-type-key.enum';
import { Agency } from '../models/agency.model';
import { HttpUrlEncodingCodec } from '../codec/http-url-encoding-codec';
import { ValueOption } from '../models/value-option.model';
import { AgencyConfigs } from '../models/group-configs.model';
import { AgenciesTableRow } from '../table-filters/agency-group-multiselect/models/agencies-table-row';
import { GroupsTableRow } from '../table-filters/agency-group-multiselect/models/groups-table-row';

@Injectable({
  providedIn: 'root',
})
export class QueryService {
  value: string;

  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  public getFilingWorkflow(filingId: string): Observable<Workflow[]> {
    return this.http
      .get<any>(`${ConfigService.INTEGRITY_SERVICE_GET_FILING_WORKFLOW}`, {
        headers: this.getHeader(),
        params: { filingId },
      })
      .pipe(
        map((data) => {
          const workflow: Workflow[] = [];
          // zero rows returned
          if (!data.results || !data.results.workflow) {
            return workflow;
          }
          // one row returned
          if (!Array.isArray(data.results.workflow)) {
            workflow.push(
              Workflow.createFromJson(
                data.results.workflow,
                data.results.the_end === 'true'
              )
            );
          }

          data.results.workflow.forEach((step: any) => {
            workflow.push(
              Workflow.createFromJson(step, step.the_end === 'true')
            );
          });

          // The last FILER step in the nominee workflow requires the nomination date to be filled out.
          // This could eventually be pushed to the workflow_state table as a 'prerequisites' column, but
          // seems unnecessary at the moment
          for (let i = data.results.workflow.length - 1; i >= 2; i--) {
            if (workflow[i].role?.surrogateKey === RoleKey.Filer) {
              workflow[i].requiresNominationDate = true;
              break;
            }
          }

          return workflow;
        })
      );
  }

  private getHeader() {
    return {
      'x-session-user-id': this.sessionService.getMaxUsername(),
    };
  }

  /*public getMinimalContactInfo(userId: string) {
    return this.http
      .get<any>(ConfigService.WSO_GET_MINIMAL_CONTACT_INFO, {
        params: { userId: userId },
      })
      .pipe(
        map((data) => {
          // zero rows returned
          if (!data.results) {
            throw Error('getMinimalContactInfo failed.');
          }

          let ci = {
            surrogate_key: userId,
            filer_firstName: data.results.rows.first_name,
            filer_middleInitial: data.results.rows.middle_initial,
            filer_lastName: data.results.rows.last_name,
          };

          return ci;
        })
      );
  }*/

  public getMinimalContactInfo(userId: string) {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_MINIMAL_CONTACT_INFO, {
      queryParams: { userId },
      rowTransformCallback: (row: any) => {
        return {
          surrogate_key: userId,
          filer_firstName: row.first_name,
          filer_middleInitial: row.middle_initial,
          filer_lastName: row.last_name,
        };
      },
    }).pipe(map((asArray) => asArray[0]));
  }

  public getContactInfoByTimestamp(userId: string, timestamp: number) {
    return this.get(
      ConfigService.INTEGRITY_SERVICE_GET_CONTACT_INFO_BY_TIMESTAMP,
      {
        queryParams: {
          userId,
          timestamp:
            timestamp.toString().length === 13
              ? Math.floor(timestamp / 1000)
              : timestamp,
        },
      }
    ).pipe(map((asArray) => asArray[0]));
  }

  public getFilerDesigneesContactInfo(filerId: string) {
    return this.get(
      ConfigService.INTEGRITY_SERVICE_GET_FILER_DESIGNEES_CONTACT_INFO,
      {
        queryParams: { filerId },
        rowTransformCallback: (row: any) => row.data,
      }
    );
  }

  public getUserAgency(userId: string) {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_USER_AGENCY, {
      queryParams: { sessionUserId: userId },
    });
  }

  public getDocumentsForAgency(agencyId: string) {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_DOCUMENTS_FOR_AGENCY, {
      queryParams: { agencyId },
      rowTransformCallback(row: any) {
        return row.data;
      },
    });
  }

  public getLandingPageConfigs(): Observable<LandingPageConfigs> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_LANDING_PAGE_CONFIGS, {
      headers: { 'x-session-user-id': '' },
      dssGroupedByElement: 'result',
      rowTransformCallback: (row: any) => row,
    }).pipe(map((asArray) => asArray[0]));
  }

  public getLoginSecurityLink(): Observable<string> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_LOGIN_SECURITY_LINK, {
      headers: { 'x-session-user-id': '' },
      rowTransformCallback: (row: any) => row,
    }).pipe(
      map((asArray) => {
        return !!asArray &&
          Array.isArray(asArray) &&
          asArray.length &&
          asArray[0].link
          ? asArray[0].link
          : '';
      })
    );
  }

  public getGroupAndParentAgency(groupId: string): Observable<any> {
    return this.httpGet(
      ConfigService.INTEGRITY_SERVICE_GET_GROUP_AND_PARENT_AGENCY,
      {
        queryParams: { groupId },
      }
    ).pipe(
      map((data) => {
        return {
          group: JSON.parse(data.results.data.group),
          agency: JSON.parse(data.results.data.agency),
        };
      })
    );
  }

  public getAgencySetting(groupId: string, setting: string): Observable<any> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_AGENCY_CONFIG_SETTING, {
      queryParams: { groupId, objectkey: setting },
      rowTransformCallback: (row: any) => row,
    }).pipe(map((asArray) => asArray[0].setting));
  }

  public getUserBasics(userId): Observable<any> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_USER_BASICS, {
      queryParams: { userId },
      dssGroupedByElement: 'users',
      dssRowName: 'rows',
      rowTransformCallback: (row: any) => row,
    }).pipe(map((asArray) => asArray[0]));
  }

  public getFilingNotifications(filingId: string): Observable<any> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_FILING_NOTIFICATIONS, {
      queryParams: {
        filingId,
        sessionUserId: this.sessionService.getMaxUsername(),
      },
      dssGroupedByElement: 'results',
      dssRowName: 'notifications',
      rowTransformCallback: (row: any) => row,
    });
  }

  /**
   * Compare - prepopulation filing
   */
  public getSourceFilingInfo(filingId: string): Observable<any> {
    return this.get(
      ConfigService.INTEGRITY_SERVICE_GET_PREPOPULATION_SOURCE_REPORT_INFO,
      {
        queryParams: {
          filingId,
          sessionUserId: this.sessionService.getMaxUsername(),
        },
        dssGroupedByElement: 'results',
        dssRowName: 'result',
        rowTransformCallback: (row: any) => row,
      }
    ).pipe(
      map((data) => {
        if (data.length) {
          data = data[0];
        }
        return data;
      })
    );
  }

  /**
   * Generic function to retrieve data from a WSO2 endpoint.
   * @parmas <url> string
   * @params <params> object e.g., queryParams: { filingId:  filingId }. To specify
   * data results set groupings, queryParams: { filingId:  filingId }, dssGroupedByElement: 'foo', dssRowName: 'bar'
   *
   * Example, pass an object such as this:
   *  let params: any = {
   *     queryParams: {
   *       filingId: filingId,
   *   }
   *  };
   */
  public get(url: string, params: any): Observable<any> {
    // Pass on query paramaters to the endpoint.
    params.queryParams = params.queryParams ?? {};

    let queryParams = {};
    params.headers = params.headers ?? this.sessionService.getNodeHeader();
    queryParams = {
      params: params.queryParams,
      headers: params.headers,
    };

    // Default the DSS group element name and row name if none given in function call.
    params.dssGroupedByElement = params.dssGroupedByElement ?? 'results';
    params.dssRowName = params.dssRowName ?? 'rows';

    return this.http.get<any>(url, queryParams).pipe(
      map((data) => {
        if (!data) {
          data = { results: { rows: [] } };
        }
        let rows: any[] = [];

        // handle zero rows returned
        if (
          '' === data[params.dssGroupedByElement] ||
          (data[params.dssGroupedByElement] &&
            data[params.dssGroupedByElement][params.dssRowName] == null)
        ) {
          // Empty array returned as results.
        }
        // handle one row returned
        else if (
          !Array.isArray(data[params.dssGroupedByElement][params.dssRowName])
        ) {
          rows = [data[params.dssGroupedByElement][params.dssRowName]];
        } else {
          rows = data[params.dssGroupedByElement][params.dssRowName];
        }

        rows.forEach((row: any, index: number) => {
          if (row.hasOwnProperty('data')) {
            // Parse JSON column if we have one. We use the column name "data" by convention.
            row.data = JSON.parse(row.data);
          }
          if (params.hasOwnProperty('rowTransformCallback')) {
            // Run row transform callback over row.
            row = params.rowTransformCallback(row);
          }
          rows[index] = row;
        });

        return rows;
      })
    );
  }

  /**
   * Add the Node header to the query params and call http.get
   */
  public httpGet(url: string, params: any): Observable<any> {
    params.queryParams = params.queryParams ?? {};
    params.headers = params.headers ?? this.sessionService.getNodeHeader();
    const queryParams = {
      params: params.queryParams,
      headers: params.headers,
    };

    return this.http.get<any>(url, queryParams);
  }

  getFilerGroups(me: string): Observable<Group[]> {
    const params = { sessionFilerId: me };

    return this.httpGet(ConfigService.INTEGRITY_SERVICE_GET_FILER_GROUPS, {
      queryParams: params,
    }).pipe(
      map((json) => {
        const groups: Group[] = [];
        if (!!json && !!json.results && !!json.results.groups) {
          if (!Array.isArray(json.results.groups)) {
            const group = new Group();
            group.name = json.results.groups.name;
            group.surrogateKey = json.results.groups.group_surrogate_key;
            group.filerDefaultFilingType =
              json.results.groups.filer_default_filing_type;
            group.agencyName = json.results.groups.agency;
            if (!!json.results.groups.filer_current_filer_status) {
              group.filerCurrentFilerStatuses = {};
              group.filerCurrentFilerStatuses[me] =
                json.results.groups.filer_current_filer_status;
            }
            groups.push(group);
          } else {
            json.results.groups.forEach((groupJson: any) => {
              const group = new Group();
              group.name = groupJson.name;
              group.surrogateKey = groupJson.group_surrogate_key;
              group.filerDefaultFilingType =
                groupJson.filer_default_filing_type;
              group.agencyName = groupJson.agency;
              if (!!groupJson.filer_current_filer_status) {
                group.filerCurrentFilerStatuses = {};
                group.filerCurrentFilerStatuses[me] =
                  groupJson.filer_current_filer_status;
              }
              groups.push(group);
            });
          }
        }
        return groups;
      })
    );
  }

  /**
   * ~ DssPersistenceFactory get
   *  A generic query method for the DSS persistence layer.
   * Constructs the WSO2 url using the queryId and calls "get".
   * @param queryId
   * @param params
   */
  public getByQueryId(queryId: string, params: any): Observable<any> {
    const serviceEndpoint: string =
      ConfigService.INTEGRITY_SERVICE_NODE_BASE + queryId;

    return this.get(serviceEndpoint, params);
  }

  public getFilingDocuments(filingId: string): Observable<Document[]> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_FILING_DOCUMENTS}`;

    return this.get(serviceEndpoint, {
      queryParams: { filingId },
      dssRowName: 'documents',
    }).pipe(map((documents) => Document.createArrayFromJson(documents)));
  }

  public canUserEditFiling(filingId: string): Observable<boolean> {
    return this.get(ConfigService.INTEGRITY_SERVICE_CAN_USER_EDIT_FILING, {
      queryParams: {
        filingId,
        sessionUserId: this.sessionService.getMaxUsername(),
      },
      rowTransformCallback: (row: any) => row,
      dssRowName: 'result',
    }).pipe(
      map((asArray) => {
        return asArray[0].can_edit === 'true';
      })
    );
  }

  /**
   * Determine if the user can download a comparison report. Reports that are purged cannot
   * be downloaded. The user must also have view access to the report.
   * EFEDS-5979
   */
  public canUserDownloadFiling(filingId: string): Observable<boolean> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_CAN_USER_DOWNLOAD_FILING}`;
    const me: string = this.sessionService.getMaxUsername();
    const params: any = { sessionUserId: me, filingId };

    return this.getSingleValue(serviceEndpoint, params).pipe(
      map((json: any) => {
        const canDownload: boolean = JSON.parse(json);
        return canDownload;
      })
    );
  }

  public getManageReviewerList(dtParams) {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_MANAGE_REVIEWER_LIST}`;
    dtParams.sessionUserId = this.sessionService.getMaxUsername();

    return this.httpGet(serviceEndpoint, { queryParams: dtParams }).pipe(
      map((res: any) => {
        return JSON.parse(res.result.json.data);
      })
    );
  }

  public getFilingDistinctValues(
    field: string,
    includeNotAssignedReports: boolean = false
  ): Observable<any[]> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_DISTINCT_VALUES}${field}`;
    const me: string = this.sessionService.getMaxUsername();

    return this.get(serviceEndpoint, {
      queryParams: {
        sessionUserId: me,
        includeNotAssignedReports,
      },
      dssRowName: 'values',
    });
  }

  public getFilingCurrentRolePendingActionNotification(
    filingId: string,
    isAgencyRouterMode: boolean
  ) {
    return this.get(
      ConfigService.INTEGRITY_SERVICE_GET_FILING_CURRENT_ROLE_PENDING_ACTION_NOTIFICATION,
      {
        queryParams: {
          filingId,
          isAgencyRouterMode,
        },
        dssGroupedByElement: 'results',
        dssRowName: 'result',
        rowTransformCallback: (row: any) => {
          return row.notification;
        },
      }
    ).pipe(map((asArray) => asArray[0]));
  }

  public getManageReviewerAlternates() {
    return this.get(
      ConfigService.INTEGRITY_SERVICE_GET_MANAGE_REVIEWER_ALTERNATES,
      {
        queryParams: {
          sessionUserId: this.sessionService.getMaxUsername(),
        },
        rowTransformCallback: (row: any) => {
          row.alternates = JSON.parse(row.alternates);

          return row;
        },
      }
    );
  }

  public doesUserHaveOgeOversightNomineeRole(role: string) {
    return this.get(
      ConfigService.INTEGRITY_SERVICE_DOES_USER_HAVE_OGE_OVERSIGHT_NOMINEE_ROLE,
      {
        queryParams: {
          sessionUserId: this.sessionService.getMaxUsername(),
          role,
        },
        dssGroupedByElement: 'results',
        dssRowName: 'has',
        rowTransformCallback: (row: any) => {
          return 'true' == row.has;
        },
      }
    ).pipe(map((asArray) => asArray[0]));
  }

  public getContactInfoHistory(userId: string) {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_CONTACT_INFO_HISTORY, {
      queryParams: {
        userId,
      },
      rowTransformCallback: (row: any) => {
        return ContactInfo.createFromDbRow(row);
      },
    });
  }

  /**
   * Retrieve data from WSO2 endpoint where a single value is returned
   * rather than rows
   *
   */
  public getSingleValue(url: string, params: any) {
    return this.httpGet(url, { queryParams: params }).pipe(
      map((res) => {
        // Reassign the object so it won't complain about the unknown structure
        const myObj: any = res;

        // Sets class variable "value"
        this.iterateObject(myObj);
        return this.value;
      })
    );
  }

  /**
   * Get the final value through the layers of objects in the return
   * e.g., {results: {group: {name: My Group Name }}}
   * Typescript doesn't allow the structure of a recursive function where you return
   * your final value because it results in a function where it appears that there is a path
   * that has no return value. To get around this, set a class variable instead.
   * https://www.c-sharpcorner.com/UploadFile/5089e0/how-to-use-recursive-function-in-typescript/
   */
  public iterateObject(obj: any) {
    for (const property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (typeof obj[property] === 'object') {
          this.iterateObject(obj[property]);
        } else {
          this.value = obj[property];
        }
      }
    }
  }

  getUserRolesForFiling(filingId: string): Observable<Role[]> {
    const endPoint = ConfigService.INTEGRITY_SERVICE_GET_USER_ROLES_FOR_FILING;
    const params = {
      sessionUserId: this.sessionService.getMaxUsername(),
      filingId,
    };

    return this.httpGet(endPoint, { queryParams: params }).pipe(
      map((data: any) => {
        // handle zero rows returned
        if (
          '' === data.results ||
          (data.results && data.results.roles === null) ||
          Object.keys(data.results).length === 0
        ) {
          return [];
        }
        // handle one row returned
        else if (!_.isArray(data.results.roles)) {
          return [data.results.roles];
        }

        return data.results.roles.map((roleJson) => Role.fromJson(roleJson));
      })
    );
  }

  getPullForwardRules(
    filingId: string,
    workflowStep: number
  ): Observable<any[]> {
    const params = {
      queryParams: {
        filingId,
        workflowStep,
      },
      dssRowName: 'rules',
      rowTransformCallback: (rule) => {
        rule.pull_to_workflow_step_number = parseInt(
          rule.pull_to_workflow_step_number,
          10
        );
        return rule;
      },
    };
    const endpoint =
      ConfigService.INTEGRITY_SERVICE_GET_FILING_WORKFLOW_STEP_PULL_FORWARD_RULES;
    return this.get(endpoint, params);
  }

  getPullForwardRulesByEndStep(
    filingId: string,
    workflowStep: number
  ): Observable<any[]> {
    const options = {
      queryParams: {
        filingId,
        workflowStep,
      },
      dssRowName: 'rules',
      rowTransformCallback: (rule) => {
        rule.pull_from_workflow_step_number = parseInt(
          rule.pull_from_workflow_step_number,
          10
        );
        return rule;
      },
    };
    const endpoint =
      ConfigService.INTEGRITY_SERVICE_GET_FILING_WORKFLOW_STEP_PULL_FORWARD_RULES_BY_END_STEP;

    return this.get(endpoint, options);
  }

  public getPasDaeoGroupsToShareNomineeReportWith(filingId): Observable<any[]> {
    const endpoint =
      ConfigService.INTEGRITY_SERVICE_GET_PAS_GROUPS_TO_SHARE_NOMINEE_REPORT_WITH;
    const params = { filingId };

    return this.httpGet(endpoint, { queryParams: params }).pipe(
      map((data: any) => {
        // handle zero rows returned
        if (
          '' == data.results ||
          (data.results && data.results.groups == null)
        ) {
          data.results = { groups: [] };
        }
        // handle one row returned
        else if (!_.isArray(data.results.groups)) {
          data.results.groups = [data.results.groups];
        }

        data = Util.fixNulls(data);

        return data.results.groups;
      })
    );
  }

  public getFilingMemoHash(
    filingId: string,
    memoSource: string
  ): Observable<string> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_FILING_MEMO_HASH, {
      queryParams: {
        filingId,
        memoSource,
      },
      dssRowName: 'hash',
    }).pipe(
      map((data: any) => {
        data = Util.fixNulls(data);
        return data[0].hash;
      })
    );
  }

  public searchByFiling(table: string, filingId: string): Observable<any> {
    return this.get(ConfigService.INTEGRITY_SERVICE_SEARCH_BY_FILING, {
      queryParams: {
        tablename: table,
        filingId,
      },
      rowTransformCallback: (row) => {
        row.data.created_at = row.data.created_at
          ? row.data.created_at
          : new Date(row.created_at).getTime();
        return row.data;
      },
    });
  }

  public getGroupNames(): Observable<any> {
    const serviceEndpoint: string =
      ConfigService.INTEGRITY_SERVICE_GET_GROUP_NAMES;

    return this.get(serviceEndpoint, {
      dssGroupedByElement: 'results',
      dssRowName: 'groups',
      rowTransformCallback: (row: any) => row,
    }).pipe(map((data: any) => data));
  }

  public getGroupData(groupId: string): Observable<Group> {
    const endpoint = ConfigService.INTEGRITY_SERVICE_GET_GROUP_DATA;
    const params = { groupId };

    return this.httpGet(endpoint, { queryParams: params }).pipe(
      map((data: any) => {
        return Group.createFromJson(JSON.parse(data.results.rows[0].data));
      })
    );
  }

  public getRelatedGroups(groupId: string): Observable<any> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_RELATED_GROUPS, {
      queryParams: {
        sessionUserId: this.sessionService.getMaxUsername(),
        groupId,
      },
      dssGroupedByElement: 'results',
      dssRowName: 'groups',
      rowTransformCallback: (row) => {
        return Group.createFromJson(row.data);
      },
    });
  }

  public purgeFiling(filingId: string) {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_PURGE_FILING}`;
    const me = this.sessionService.getMaxUsername();
    const params = { sessionUserId: me, filingId };

    return this.httpGet(serviceEndpoint, { queryParams: params });
  }

  public deleteFiling(filingId: string) {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_DELETE_FILING}`;
    const me = this.sessionService.getMaxUsername();
    const params = { sessionUserId: me, filingId };

    return this.httpGet(serviceEndpoint, { queryParams: params });
  }

  /**
   * Compare - prepopulation filing
   */
  public getCompareData(
    filingId: string,
    gridId: string,
    deserialize?: (row: any) => any
  ): Observable<any> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_COMPARE_DATA, {
      queryParams: {
        gridId,
        filingId,
      },
      dssGroupedByElement: 'results',
      dssRowName: 'rows',
      rowTransformCallback: deserialize,
    });
  }

  /**
   * For the checkmark in the left navigation for Compare, get the compare
   * flags for this filing and convert the value
   *  "true" => true
   *  "false" => false.
   *
   * ~ DssPersistenceFactory getReviewerCompareFlags line 1216
   */
  public getCompareFlags(filingId: string): Observable<any> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_COMPARE_FLAGS, {
      queryParams: {
        filingId,
      },
      dssGroupedByElement: 'result',
      dssRowName: 'flags',
    }).pipe(
      map((data: any) => {
        let row: any = {};
        if (Array.isArray(data) && data.length) {
          row = data[0];
        }
        const keys = Object.keys(row);
        keys.forEach((key) => {
          row[key] = row[key] == 'true';
        });
        return row;
      })
    );
  }

  /**
   * ~ DssPersistenceFactory.getRecordsManagementBulkActionJobs
   */
  public getRecordsManagementBulkActionJobs(): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_RECORDS_MANAGEMENT_BULK_ACTION_JOBS}`;
    const params = { sessionUserId: this.sessionService.getMaxUsername() };

    return this.httpGet(serviceEndpoint, { queryParams: params }).pipe(
      map((res: any) => {
        return JSON.parse(res.result.json.data);
      })
    );
  }

  public getBulkExportReportsJobs(param: any): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_BULK_EXPORT_REPORTS_JOBS}`;

    return this.httpGet(serviceEndpoint, { queryParams: param }).pipe(
      map((res: any) => {
        const data: any = { rows: [] };
        if (
          !!res &&
          !!res.result &&
          !!res.result.json &&
          !!res.result.json.data
        ) {
          return JSON.parse(res.result.json.data);
        } else {
          return data;
        }
      })
    );
  }

  public getDescendantGroups(groupSurrogateKey: string): Observable<Group[]> {
    const me = this.sessionService.getMaxUsername();

    return this.get(ConfigService.INTEGRITY_SERVICE_GET_DESCENDANT_GROUPS, {
      queryParams: {
        groupId: groupSurrogateKey,
        sessionUserId: me,
      },
      dssRowName: 'groups',
      rowTransformCallback: (json: any) => {
        return Group.createFromJson(json.data);
      },
    });
  }

  /**
   * ~ DssPersistenceFactory.getUserGroupsAndAgencies
   */
  public getUserGroupsAndAgencies(): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_USER_GROUPS_AND_AGENCIES}`;
    const me = this.sessionService.getMaxUsername();
    const params: any = { queryParams: { sessionUserId: me } };

    return this.get(serviceEndpoint, params);
  }

  public getMgmtReportAgencyList(reportName: string): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_MGMT_REPORT_AGENCY_LIST}`;
    const params: any = {
      queryParams: {
        sessionUserId: this.sessionService.getMaxUsername(),
        reportName,
      },
    };

    return this.get(serviceEndpoint, params);
  }

  public getMgmtReportGroupList(reportName: string): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_MGMT_REPORT_GROUP_LIST}`;
    const me = this.sessionService.getMaxUsername();
    const params: any = {
      queryParams: {
        sessionUserId: me,
        reportName,
      },
    };

    return this.get(serviceEndpoint, params);
  }

  public getFindUserAgencyList(): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_FIND_USER_AGENCY_LIST}`;
    const me = this.sessionService.getMaxUsername();
    const params: any = { queryParams: { sessionUserId: me } };

    return this.get(serviceEndpoint, params);
  }

  public getFindUserGroupList(): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_FIND_USER_GROUP_LIST}`;
    const me = this.sessionService.getMaxUsername();
    const params: any = {
      queryParams: {
        sessionUserId: me,
      },
    };

    return this.get(serviceEndpoint, params);
  }

  public getReviewerFilingValuesAgencyList(
    includeNotAssignedReports = false
  ): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_REVIEWER_FILING_VALUES_AGENCY_LIST}`;
    const me = this.sessionService.getMaxUsername();
    const params: any = {
      queryParams: {
        sessionUserId: me,
        includeNotAssignedReports,
      },
    };

    return this.get(serviceEndpoint, params);
  }

  public getReviewerFilingValuesGroupList(
    includeNotAssignedReports = false
  ): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_REVIEWER_FILING_VALUES_GROUP_LIST}`;
    const me = this.sessionService.getMaxUsername();
    const params: any = {
      queryParams: {
        sessionUserId: me,
        includeNotAssignedReports,
      },
    };

    return this.get(serviceEndpoint, params);
  }

  public getBulkAssignReportsJobs(param: any): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_BULK_ASSIGN_REPORTS_JOBS}`;

    return this.httpGet(serviceEndpoint, { queryParams: param }).pipe(
      map((res: any) => {
        const data: any = { rows: [] };
        if (
          !!res &&
          !!res.result &&
          !!res.result.json &&
          !!res.result.json.data
        ) {
          return JSON.parse(res.result.json.data);
        } else {
          return data;
        }
      })
    );
  }
  
  public getBulkAssignReportsJob(jobId: string, deserialize?: (row: any) => any) {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_BULK_ASSIGN_REPORTS_JOB}`;

    const params: any = {
      queryParams: {
        jobId,
        sessionUserId: this.sessionService.getMaxUsername(),
      },
      rowTransformCallback: deserialize
    };

    return this.get(serviceEndpoint, params)
      .pipe(map((asArray) => asArray[0]));
  }

  public getFilersInAgency(agencyId: string): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_FILERS_IN_AGENCY}`;
    const me: string = this.sessionService.getMaxUsername();

    const params: any = {
      dssGroupedByElement: 'results',
      dssRowName: 'filers',
      queryParams: {
        agencyId,
        sessionUserId: me,
      },
    };

    return this.get(serviceEndpoint, params).pipe(
      map((filers: any) => {
        return filers;
      })
    );
  }

  public getDefaultBulkReportAssignments(
    queryParams: any,
    deserialize?: (row: any) => any
  ): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_DEFAULT_BULK_REPORT_ASSIGNMENTS}`;
    queryParams.sessionUserId = this.sessionService.getMaxUsername();

    const params: any = {
      dssGroupedByElement: 'results',
      dssRowName: 'filers',
      queryParams,
      rowTransformCallback: deserialize,
    };

    return this.get(serviceEndpoint, params);
  }

  public getFilerExistingAssignments(
    queryParams: any,
    deserialize?: (row: any) => any
  ): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_FILER_EXISTING_ASSIGNMENTS}`;

    const params: any = {
      dssGroupedByElement: 'results',
      dssRowName: 'rows',
      queryParams,
      rowTransformCallback: deserialize,
    };

    return this.get(serviceEndpoint, params);
  }

  /**
   * EFEDS-6465 - disabled warning checks - code not verified working in v2
   */
  public filerAssignmentLookup(params: any): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_LOOKUP_FILING_BY_AGENCY_USER_YEAR_ITEM_TYPE}`;

    return this.httpGet(serviceEndpoint, { queryParams: params }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public getAllRoles(): Observable<any[]> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_ALL_ROLES}`;
    return this.get(serviceEndpoint, { dssRowName: 'roles' });
  }

  public getAllFilingTypes(): Observable<any> {
    const serviceEndpoint: string = `${ConfigService.INTEGRITY_SERVICE_GET_ALL_FILING_TYPES}`;
    return this.get(serviceEndpoint, { dssRowName: 'types' });
  }

  public getRecordsSearchResults(dtParams) {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_REVIEWER_RECORDS_SEARCH_LIST}`;
    dtParams.sessionUserId = this.sessionService.getMaxUsername();

    return this.httpGet(serviceEndpoint, { queryParams: dtParams }).pipe(
      map((res: any) => {
        return JSON.parse(res.result.json.data);
      })
    );
  }

  public getAllAgencies(): Observable<AgenciesTableRow[]> {
    const me = this.sessionService.getMaxUsername();
    const params: any = {
      queryParams: {
        sessionUserId: me,
      },
      rowTransformCallback: (row: any) => {
        return AgenciesTableRow.create(row);
      },
    };

    return this.get(
      ConfigService.INTEGRITY_SERVICE_GET_REVIEWER_ALL_AGENCY_LIST,
      params
    );
  }

  public getAllGroups(): Observable<GroupsTableRow[]> {
    const me = this.sessionService.getMaxUsername();
    const params: any = {
      queryParams: {
        sessionUserId: me,
      },
      rowTransformCallback: (row: any) => {
        return GroupsTableRow.create(row);
      },
    };
    return this.get(
      ConfigService.INTEGRITY_SERVICE_GET_REVIEWER_ALL_GROUPS_LIST,
      params
    );
  }

  public getBulkProvisionUsersJobs(dtParams): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_BULK_PROVISION_USERS_JOBS}`;
    dtParams.sessionUserId = this.sessionService.getMaxUsername();

    return this.httpGet(serviceEndpoint, { queryParams: dtParams }).pipe(
      map((res: any) => {
        return JSON.parse(res.result.json.data);
      })
    );
  }

  public getManageAgenciesAndGroupsAgencyList(
    search: string,
    sortColumn: number,
    sortDirection: string,
    offset: number,
    limit: number,
    forHierarchy: boolean = false
  ): Observable<any[]> {
    const params: any = {
      queryParams: {
        sessionUserId: this.sessionService.getMaxUsername(),
        search,
        sortColumn,
        sortDirection,
        offset,
        limit,
        forHierarchy: forHierarchy ? 1 : 0,
      },
    };

    return this.get(
      ConfigService.INTEGRITY_SERVICE_GET_MANAGE_AGENCIES_AND_GROUPS_AGENCY_LIST,
      params
    );
  }

  public getManageAgenciesAndGroupsGroupList(
    search: string,
    sortColumn: number,
    sortDirection: string,
    offset: number,
    limit: number,
    parentSurrogateKey: string | null = null
  ): Observable<any[]> {
    const params: any = {
      queryParams: {
        sessionUserId: this.sessionService.getMaxUsername(),
        search,
        sortColumn,
        sortDirection,
        offset,
        limit,
        parentSurrogateKey: parentSurrogateKey ? parentSurrogateKey : '',
      },
      rowTransformCallback: (json: any) => {
        json.filingTypes = JSON.parse(json.filingTypes);
        return json;
      },
    };

    return this.get(
      ConfigService.INTEGRITY_SERVICE_GET_MANAGE_AGENCIES_AND_GROUPS_GROUP_LIST,
      params
    );
  }

  public getFilingTypes(): Observable<FilingType[]> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_FILING_TYPES, {
      queryParams: {},
      rowTransformCallback: (json: any) => {
        return FilingType.createFromJson(json);
      },
    });
  }

  public getWorkflowTypes(
    filingTypes: Map<string, FilingType> | null = null
  ): Observable<WorkflowType[]> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_WORKFLOW_TYPES, {
      queryParams: {},
      rowTransformCallback: (json: any) => {
        return WorkflowType.createFromJson(json, filingTypes);
      },
    });
  }

  public findCorrespondingSpecialAgencyAndGroup(
    agencyType: string,
    administrationIdentifier: string,
  ): Observable<any> {
    const params = {
      agencyType,
      administrationIdentifier,
    };

    return this.get(ConfigService.INTEGRITY_SERVICE_FIND_CORRESPONDING_SPECIAL_AGENCY_AND_GROUP, {
      queryParams: params,
      dssRowName: 'result',
    }).pipe(
      map((asArray) => {
        const results = asArray[0];
        results.hasCorrespondingAgency = results.hasCorrespondingAgency === 'true';
        results.hasCorrespondingGroup = results.hasCorrespondingGroup === 'true';
        return results;
      })
    );
  }

  public getPermissionChangeLogForGroup(
    dtParams: any,
    deserialize: (row: any) => any
  ): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_PERMISSION_CHANGE_LOG_FOR_GROUP}`;
    
    dtParams.sessionUserId = this.sessionService.getMaxUsername();
    
    return this.httpGet(
      serviceEndpoint,
      {
        queryParams: dtParams,
      }
    ).pipe(
      map((res) => {
        if (!res || !res.result || !res.result.json || !res.result.json.data) {
          throw new Error(`Error loading permission changelog for ${dtParams.groupId}.`);   
        }
        const data = JSON.parse(res.result.json.data);
        if (!data.rows || !Array.isArray(data.rows)) {
          throw new Error(`Invalid json format for permission change log.`);
        }
        for (let i = 0; i < data.rows.length; i++) {
          data.rows[i] = deserialize(data.rows[i]);
        }
        return data;
      })
    );
  }

  public setFlashMessage(message: string): Observable<string> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_SET_FLASH_MESSAGE}`;

    const params: any = {
      queryParams: {
        sessionUserId: this.sessionService.getMaxUsername(),
        message,
      },
    };

    return this.get(serviceEndpoint, params).pipe(
      map((rows: string) => {
        if (Array.isArray(rows) && rows.length && !!rows[0].status)
          return rows[0].status;
        else {
          throw Error('Error: Set Flash Message failed');
        }
      })
    );
  }

  public facetedSearchForUsers(
    isNominee: boolean,
    params: any
  ): Observable<any> {
    const serviceEndpoint = isNominee
      ? ConfigService.INTEGRITY_SERVICE_FACETED_SEARCH_FOR_USERS
      : ConfigService.INTEGRITY_SERVICE_FACETED_SEARCH_FOR_NON_NOMINEE_USERS;

    const config: any = {
      params: new HttpParams({ encoder: new HttpUrlEncodingCodec() }),
    };

    Object.keys(params).forEach(function (key) {
      config.params = config.params.append(key, params[key]);
    });

    return this.httpGet(serviceEndpoint, { queryParams: config.params }).pipe(
      map((res: any) => {
        return JSON.parse(res.results.rows.data);
      })
    );
  }

  public getFlashMessage(): Observable<string> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_FLASH_MESSAGE}`;

    if (!this.sessionService.isLoggedIn) {
      return of('');
    }

    const params: any = {
      queryParams: {
        sessionUserId: this.sessionService.getMaxUsername(),
      },
    };

    return this.get(serviceEndpoint, params).pipe(
      map((data: any) => {
        if (
          Array.isArray(data) &&
          data.length &&
          data[0].message !== undefined
        ) {
          return data[0].message.replace(/\s+/g, ' ').replace(/^\s+|\s+$/, '');
        } else {
          throw Error('Error: Get Flash Message failed');
        }
      })
    );
  }

  public getInboundNomineeGroups(): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_INBOUND_NOMINEE_GROUPS}`;
    return this.get(serviceEndpoint, { dssRowName: 'groups' });
  }

  public getInboundNomineeGroupsForUser(): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_INBOUND_NOMINEE_GROUPS_FOR_USER}`;

    const params: any = {
      dssRowName: 'groups',
      queryParams: {
        sessionUserId: this.sessionService.getMaxUsername(),
      },
    };

    return this.get(serviceEndpoint, params);
  }

  public getAutocompleteFilingReviewers(target: string): Observable<any> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_AUTOCOMPLETE_FILING_REVIEWERS}`;
    const me: string = this.sessionService.getMaxUsername();

    const params: any = {
      queryParams: {
        sessionUserId: me,
        name: target,
      },
    };

    return this.get(serviceEndpoint, params);
  }

  public getAllInactiveUserIds(): Observable<string[]> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_ALL_INACTIVE_USER_IDS, {
      dssGroupedByElement: 'users',
      dssRowName: 'rows',
      queryParams: {},
      rowTransformCallback: (json: any) => {
        return json.id;
      },
    });
  }

  public getUserRolesForOtherUser(otherId: string): Observable<any[]> {
    return this.get(
      ConfigService.INTEGRITY_SERVICE_GET_USER_ROLES_FOR_OTHER_USER,
      {
        queryParams: { other_user_id: otherId },
      }
    );
  }

  public getGroupAndAncestorUserNames(
    groupId: string
  ): Observable<{ surrogate_key: string; full_name: string }[]> {
    return this.get(
      ConfigService.INTEGRITY_SERVICE_GET_GROUP_AND_ANCESTOR_USER_NAMES,
      {
        queryParams: { groupId },
      }
    );
  }

  public getGroupWithAncestors(groupId: string): Observable<BaseGroup> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_GROUP_AND_ANCESTORS, {
      queryParams: { groupId },
      rowTransformCallback: (json: any) => {
        let group: BaseGroup;
        if (json.data.isAgency) {
          group = Agency.createFromJson(json.data);
        } else {
          group = Group.createFromJson(json.data);
        }
        group.version = json.version;
        return group;
      },
    }).pipe(
      map((asArray: BaseGroup[]) => {
        const idMap = _.keyBy(asArray, 'surrogateKey');

        // Make parent/child associations
        asArray.forEach((group) => {
          if (group.parentSurrogateKey) {
            const parent = idMap[group.parentSurrogateKey];
            group.parent = parent;
            parent.addChild(group);
          }
        });

        // Find the group matching the original ID
        const target = asArray.find((group) => group.surrogateKey == groupId);
        if (!target) {
          throw new Error(`Error loading group ${groupId}.`);
        }

        return target;
      })
    );
  }

  public getAgencyGroupsUsedFilingTypes(
    agencyId: string
  ): Observable<Map<FilingTypeKey, boolean>> {
    return this.get(
      ConfigService.INTEGRITY_SERVICE_GET_AGENCY_GROUPS_USED_FILING_TYPES,
      {
        queryParams: { agencyId },
        rowTransformCallback: (json: any) => {
          const used = new Map<FilingTypeKey, boolean>();
          used.set(
            FilingTypeKey.General,
            json[FilingTypeKey.General.toString()] == 'true'
          );
          used.set(
            FilingTypeKey.Confirmed,
            json[FilingTypeKey.Confirmed.toString()] == 'true'
          );
          return used;
        },
      }
    ).pipe(
      map((asArray) => {
        return asArray[0];
      })
    );
  }

  public getLastTestEmailForAgency(agencySurrogateKey: string) {
    return this.get(
      ConfigService.INTEGRITY_SERVICE_GET_LAST_TEST_EMAIL_FOR_AGENCY,
      {
        queryParams: { agencySurrogateKey },
        dssRowName: 'result',
      }
    ).pipe(
      map((asArray) => {
        return asArray[0];
      })
    );
  }

  public isAgencyNameUnique(agency: Agency): Observable<boolean> {
    return this.get(ConfigService.INTEGRITY_SERVICE_IS_AGENCY_NAME_UNIQUE, {
      queryParams: {
        name: agency.name,
        agencyId: agency.surrogateKey ? agency.surrogateKey : null,
      },
    }).pipe(
      map((asArray) => {
        return asArray[0].unique == 'true';
      })
    );
  }

  public isGroupNameUnique(group: Group): Observable<boolean> {
    return this.get(ConfigService.INTEGRITY_SERVICE_IS_GROUP_NAME_UNIQUE, {
      queryParams: {
        name: group.name,
        groupId: group.surrogateKey ? group.surrogateKey : null,
        parentId: group.parentSurrogateKey,
      },
    }).pipe(
      map((asArray) => {
        return asArray[0].unique == 'true';
      })
    );
  }

  public getGroupUsedFilingTypes(
    groupId: string
  ): Observable<Map<FilingTypeKey, boolean>> {
    return this.get(
      ConfigService.INTEGRITY_SERVICE_GET_GROUP_USED_FILING_TYPES,
      {
        queryParams: {
          groupId,
        },
        rowTransformCallback: (json: any) => {
          json[FilingTypeKey.General] = 'true' == json[FilingTypeKey.General];
          json[FilingTypeKey.Confirmed] =
            'true' == json[FilingTypeKey.Confirmed];
          return json;
        },
      }
    ).pipe(
      map((asArray) => {
        const onlyRow = asArray[0];
        const map = new Map<FilingTypeKey, boolean>();
        for (let key in onlyRow) {
          map.set(key as FilingTypeKey, onlyRow[key]);
        }
        return map;
      })
    );
  }

  public getWorkflows(
    workflowTypes: WorkflowType[]
  ): Observable<Map<WorkflowType, Workflow[]>> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_WORKFLOWS, {
      queryParams: {},
      rowTransformCallback: (json: any) => {
        json.role = json.role_surrogate_key;
        return json;
      },
    }).pipe(
      map((workflowStates: any[]) => {
        const workflows = new Map<WorkflowType, Workflow[]>();
        workflowTypes.forEach((workflowType) => {
          const states = workflowStates.filter(
            (state) =>
              state.workflow_type_surrogate_key == workflowType.surrogateKey
          );
          if (states.length) {
            workflows.set(
              workflowType,
              _.sortBy(
                states.map((state) =>
                  Workflow.createFromJson(state, state.the_end == 'true')
                ),
                'step'
              )
            );
          }
        });

        return workflows;
      })
    );
  }

  public getGroupFilers(groupId: string): Observable<any[]> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_GROUP_FILERS, {
      queryParams: { groupId },
      rowTransformCallback: (json: any) => {
        json.is_cloaked = json.is_cloaked == 'true';
        return json;
      },
    });
  }

  public countFilingsByRoughStatusForUsersInGroup(
    groupId: string,
    userIds: string[]
  ): Observable<any[]> {
    return this.get(
      ConfigService.INTEGRITY_SERVICE_COUNT_FILINGS_BY_ROUGH_STATUS_FOR_USERS_IN_GROUP,
      {
        queryParams: { groupId, userIds: userIds.join(',') },
        rowTransformCallback: (stats: any) => {
          if (!!stats.count && !isNaN(parseInt(stats.count, 10))) {
            stats.count = parseInt(stats.count, 10);
          }
          return stats;
        },
      }
    );
  }

  public getNonTerminalFilingIdsForUserAndGroup(
    groupId: string,
    userId: string
  ): Observable<any[]> {
    return this.get(
      ConfigService.INTEGRITY_SERVICE_GET_NON_TERMINAL_FILING_IDS_FOR_USER_AND_GROUP,
      {
        queryParams: { groupId, userId },
      }
    );
  }

  public getChildrenGroups(groupId: string): Observable<BaseGroup[]> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_CHILDREN_GROUPS, {
      queryParams: { groupId },
      rowTransformCallback: (json: any) => {
        let group: BaseGroup;
        if (json.data.isAgency) {
          // should never happen unless nested agencies
          group = Agency.createFromJson(json.data);
        } else {
          group = Group.createFromJson(json.data);
        }
        group.version = json.version;
        return group;
      },
    });
  }

  public getReleaseTargetAgencyDetails(groupId: string): Observable<any> {
    return this.get(
      ConfigService.INTEGRITY_SERVICE_GET_NOMINEE_RELEASE_AGENCY_DETAILS,
      {
        queryParams: {
          groupId: groupId,
        },
        dssRowName: 'agency',
      }
    ).pipe(
      map((data) => {
        return data.length ? data[0] : {};
      })
    );
  }

  public getFilingLastRevision(
    filingId: string,
    certifyTime: number
  ): Observable<{ name: string; revision: number } | undefined> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_FILING_LAST_REVISION, {
      queryParams: {
        filingId,
        certifyTime,
      },
      dssRowName: 'date',
      rowTransformCallback: (json: any) => {
        if (json.revision) {
          json.revision = parseInt(json.revision);
        }
        return json;
      },
    }).pipe(
      map((asArray: { name: string; revision: number }[]) => {
        return asArray.length ? asArray[0] : undefined;
      })
    );
  }

  public getFilingRevisionDates(
    filingId: string,
    certifyTime: number
  ): Observable<{ name: string; revision: number }[] | undefined> {
    return this.get(ConfigService.INTEGRITY_SERVICE_GET_FILING_REVISION_DATES, {
      queryParams: {
        filingId,
        certifyTime,
      },
      dssRowName: 'dates',
    });
  }

  public deleteNotStartedFilingsForUsersInGroup(
    groupId: string,
    userIds: string[]
  ): Observable<any> {
    return this.get(
      ConfigService.INTEGRITY_SERVICE_DELETE_NOT_STARTED_FILINGS_FOR_USERS_IN_GROUP,
      {
        queryParams: {
          groupId,
          userIds,
          sessionUserId: this.sessionService.getMaxUsername(),
        },
      }
    );
  }

  public getChildrenCount(groupId: string): Observable<number> {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_CHILDREN_COUNT}`;
    const params: any = { groupId };

    return this.getSingleValue(serviceEndpoint, params).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  public getMergedAccountUsernames(
    deserialize: (row: any) => Array<ValueOption>
  ): Observable<Array<ValueOption>> {
    return this.get(
      `${ConfigService.INTEGRITY_SERVICE_GET_MERGED_ACCOUNT_USERNAMES}`,
      {
        queryParams: {},
        rowTransformCallback: deserialize,
      }
    ).pipe(
      map((values: Array<ValueOption>) => {
        return values;
      })
    );
  }

  public getMergedAccountEmails(
    deserialize: (row: any) => Array<ValueOption>
  ): Observable<Array<ValueOption>> {
    return this.get(
      `${ConfigService.INTEGRITY_SERVICE_GET_MERGED_ACCOUNT_EMAILS}`,
      {
        queryParams: {},
        rowTransformCallback: deserialize,
      }
    ).pipe(
      map((values: Array<ValueOption>) => {
        return values;
      })
    );
  }

  public getMergedAccountAgencies(
    deserialize: (row: any) => Array<ValueOption>
  ): Observable<Array<ValueOption>> {
    return this.get(
      `${ConfigService.INTEGRITY_SERVICE_GET_MERGED_ACCOUNT_AGENCIES}`,
      {
        queryParams: {},
        rowTransformCallback: deserialize,
      }
    ).pipe(
      map((values: Array<ValueOption>) => {
        return values;
      })
    );
  }

  public getBulkExportReports(
    params: any,
    deserialize?: (row: any) => any
  ): Observable<any> {
    params.sessionUserId = this.sessionService.getMaxUsername();
    return this.get(
      `${ConfigService.INTEGRITY_SERVICE_GET_BULK_EXPORT_REPORTS}`,
      {
        queryParams: params,
        rowTransformCallback: deserialize,
      }
    );
  }

  public getFilingDetails(
    surrogateKey: string,
    deserialize?: (row: any) => any
  ): Observable<any> {
    const params = {
      surrogateKey,
    };

    return this.get(`${ConfigService.INTEGRITY_SERVICE_GET_FILING_DETAILS}`, {
      dssGroupedByElement: 'results',
      dssRowName: 'rows',
      queryParams: params,
      rowTransformCallback: deserialize,
    });
  }

  public getSecurityLevelChangeLog(
    agencySurrogateKey: string,
    deserialize?: (row: any) => any
  ): Observable<any> {
    const params = {
      agencySurrogateKey,
    };
    return this.get(
      `${ConfigService.INTEGRITY_SERVICE_GET_SECURITY_LEVEL_CHANGE_LOG}`,
      {
        dssGroupedByElement: 'results',
        dssRowName: 'rows',
        queryParams: params,
        rowTransformCallback: deserialize,
      }
    );
  }
  
  public getFilingExtensions(
    filingId: string,
    deserialize?: (row: any) => any
  ): Observable<any> {
    const params: any = {
      filingId,
      sessionUserId: this.sessionService.getMaxUsername(),
    };

    return this.get(
      `${ConfigService.INTEGRITY_SERVICE_GET_FILING_EXTENSIONS}`,
      {
        dssGroupedByElement: 'results',
        dssRowName: 'rows',
        queryParams: params,
        rowTransformCallback: deserialize,
      }
    );
  }

  public getFilingReviewerExtensionRequestDecisionNotification(
    filingId: string
  ): Observable<AgencyConfigs> {
    return this.get(
      ConfigService.INTEGRITY_SERVICE_GET_FILING_REVIEWER_EXTENSION_REQUEST_DECISION_NOTIFICATION,
      {
        queryParams: {
          filingId,
        },
        dssGroupedByElement: 'results',
        dssRowName: 'result',
        rowTransformCallback: (row: any) => {
          return AgencyConfigs.createFromJson(row);
        },
      }
    ).pipe(map((asArray) => asArray[0]));
  }

  public getPrepopulatedChanges(
    filingId: string,
    deserialize?: (row: any) => any
  ): Observable<any> {
    const params = {
      filingId,
      sessionUserId: this.sessionService.getMaxUsername(),
    };
    return this.get(
      `${ConfigService.INTEGRITY_SERVICE_GET_PREPOPULATED_CHANGES}`,
      {
        queryParams: params,
        rowTransformCallback: deserialize,
      }
    );
  }

  public getExtensionReviewers(filingId): Observable<string[]> {
    const endpoint = `${ConfigService.INTEGRITY_SERVICE_GET_EXTENSION_REVIEWERS}`;
    const params = {
      filingId,
    };

    return this.get(endpoint, {
      queryParams: params,
      dssGroupedByElement: 'results',
      rowTransformCallback: (row: any) => {
        return !!row.extension_reviewers ? row.extension_reviewers : [];
      },
      dssRowName: 'rows',
    }).pipe(map((asArray) => asArray[0]));
  }

  public getPendingExtensionRequestCountForAgency(
    agencyId
  ): Observable<number> {
    const endpoint = `${ConfigService.INTEGRITY_SERVICE_GET_PENDING_EXTENSION_REQUEST_COUNT_FOR_AGENCY}`;
    const params = {
      agencyId,
    };

    return this.get(endpoint, {
      queryParams: params,
      dssGroupedByElement: 'results',
      rowTransformCallback: (row: any) => {
        return !isNaN(row.pending_count) ? parseInt(row.pending_count, 10) : 0;
      },
      dssRowName: 'rows',
    }).pipe(map((asArray) => asArray[0]));
  }

  public hasNomineeFilingChangedAfterPreclearance(
    filingId: string,
    deserialize?: (row: any) => any
  ): Observable<any> {
    const params: any = {
      filingId,
      sessionUserId: this.sessionService.getMaxUsername(),
    };

    return this.get(
      `${ConfigService.INTEGRITY_SERVICE_HAS_NOMINEE_FILING_CHANGED_AFTER_PRECLEARANCE}`,
      {
        dssGroupedByElement: 'results',
        rowTransformCallback: deserialize,
        dssRowName: 'result',
        queryParams: params,
      }
    ).pipe(
      map((asArray) => {
        return asArray[0];
      })
    );
  }
}
