import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

export enum LogLevel {
  ERROR = 'error',
  INFO = 'info',
}

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor() {}

  public static log(
    type: string,
    message: string | { statusMessage: string }
  ): void {
    switch (type) {
      case 'error': {
        console.error(JSON.stringify(message));
        if (ConfigService.DEBUG) {
          if (
            typeof message !== 'string' &&
            message.hasOwnProperty('statusMessage')
          ) {
            alert('ERROR: ' + message.statusMessage);
          } else {
            alert('ERROR: ' + message);
          }
        }
        break;
      }
      case 'info': {
        console.log('INFO: %s', message);
        break;
      }
      default: {
        console.log(message);
      }
    }
  }
}
