import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { SessionService } from './session.service';
import { map, catchError, mergeMap, delay, takeUntil, tap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { Observable, of, forkJoin, throwError, Subject } from 'rxjs';
import { Memo } from '../../modules/filer/filing/memo/memo.model';
import { FormDataPersistenceService } from './form-data-persistence.service';
import { Cell } from '../../modules/reviewer/records-management/bulk-action/bulk-action.component';
import { Filing, Filing278 } from '../models/filing.model';
import { LogService } from './log.service';
import { BroadcastService } from './broadcast.service';
import { environment } from '../../../environments/environment';
import { FilingIndicativeData } from '../models/filing-indicative-data.model';

@Injectable({
  providedIn: 'root',
})
/**
 * Replaces IntegrityServicePersistenceFactory
 */
export class IntegrityPersistenceService implements OnDestroy {
  protected destroyed$: Subject<boolean> = new Subject();
  documentSaves: { [x: string]: any[] } = {};

  constructor(
    private sessionService: SessionService,
    private http: HttpClient,
    private formDataPersistenceService: FormDataPersistenceService,
    private broadcastService: BroadcastService,
  ) {}

  /**
   * Call the endpoint with "get" rather than post to determine if the user is allowed to reset the filing.
   */
  public canResetFiling(filingId: string) {
    const serviceEndpoint =
      `${ConfigService.INTEGRITY_SERVICE_RESET_FILING}`.replace(
        '{filingid}',
        filingId
      );

    console.log(
      'IntegrityPersistenceService.resetFiling endpoint -> ' + serviceEndpoint
    );

    const config = {
      params: {},
      headers: {
        'Content-Type': 'application/json',
        'x-session-user-id': this.sessionService.getMaxUsername(),
      },
    };

    return this.http.get<any>(serviceEndpoint, config).pipe(
      map((data: any) => {
        if (data.status == 0 && data.results == true) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  public resetFiling(filingId: string) {
    const serviceEndpoint =
      `${ConfigService.INTEGRITY_SERVICE_RESET_FILING}`.replace(
        '{filingid}',
        filingId
      );

    console.log(
      'IntegrityPersistenceService.resetFiling endpoint -> ' + serviceEndpoint
    );

    const config = {
      params: {},
      headers: { 'x-session-user-id': this.sessionService.getMaxUsername() },
    };

    return this.http.post<any>(serviceEndpoint, {}, config).pipe(
      map((data: any) => {
        this.formDataPersistenceService.notifyParentWindowOfFilingChange();
        return data;
      })
    );
  }

  /**
   * OGE-8619 - Return the updated indicativeData, if applicable, with filing so we have the
   * updated version. IndicativeData may be updated on the back end when original due date
   * changes, resulting in updates to the Appointment Date or Termination Date.
   */
  public saveFilingFragment(filingId: string, fragment: any): Observable<any> {
    const endpoint =
      ConfigService.INTEGRITY_SERVICE_SAVE_FILING_FRAGMENT.replace(
        '{filingid}',
        filingId
      );

    console.log(
      'IntegrityServicePersistenceFactory.saveFilingFragment endpoint -> ' +
      endpoint
    );
    
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-session-user-id', this.sessionService.getMaxUsername());

    const config = {
      params: {},
      headers
    };

    const errorMsg = `saveFilingFragment failed. Status != 0 {{status}} URL=${endpoint}, params=`;
    const saves = this.getDocumentSaves('filing', filingId);
    
    const blocker = forkJoin(saves.length ? saves : of(['']));
    return blocker.pipe(
      mergeMap(() => {
        return this.http.put(endpoint, fragment, config)
          .pipe(
            tap({
              next: (data: any) => {
                if (data.status !== 0) {
                  LogService.log(
                    'error', errorMsg.replace(/{{status}}/, data.status)
                  );
                  throw new Error('update-filing');
                }
              },
              error: err => {
                LogService.log('error', errorMsg.replace(/{{status}}/, ''));
                throw new Error('update-filing');
              },
            }))
          .pipe(
            catchError((error) => {
              return throwError(error);
            }),
            map((data: any) => {
              const updatedFiling = Filing278.createFromJson(
                  data.results.filing.values.values.data[0][1]
              );
              let updatedIndicativeData: FilingIndicativeData | null = null;
              if (
                !!data.results.indicativeData && 
                !!data.results.indicativeData.values && 
                !!data.results.indicativeData.values.values && 
                !!data.results.indicativeData.values.values.data &&
                Array.isArray(data.results.indicativeData.values.values.data) &&
                Array.isArray(data.results.indicativeData.values.values.data[0]) &&
                data.results.indicativeData.values.values.data[0].length > 1 
              ) {
                  updatedIndicativeData = FilingIndicativeData.createFromJson(
                    data.results.indicativeData.values.values.data[0][1]
                );
              }
              this.formDataPersistenceService.notifyParentWindowOfFilingChange(updatedFiling.filingId);
              this.broadcastService.broadcast('filing-updated', {version: updatedFiling.version});
              return {filing: updatedFiling, indicativeData: updatedIndicativeData};
            }));
      }));
  }

  private getDocumentSaves(formId: string, documentId: string) {
    var key = formId + '-' + documentId;
    this.documentSaves[key] = this.documentSaves[key] ?? [];

    // Send a copy snapshot in time of the promises array back to the $q.all() in the calling function.
    var promises: any[] = [];
    for (var i = 0; i < this.documentSaves[key].length; i++) {
      promises.push(this.documentSaves[key][i]);
    }

    // Add the promise for the calling function to the promises stack after snapshot generated.
    //this.documentSaves[key].push(newPromise);

    return promises;
  }

  public updateMemo(
    filingId: string,
    memoSource: string,
    newMemo: string
  ): Observable<Memo> {
    let serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_FILING_MEMO}`
      .replace('{filingId}', filingId)
      .replace('{source}', memoSource);

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-session-user-id', this.sessionService.getMaxUsername());

    const config = {
      params: {},
      headers: headers,
    };

    return this.http.put<Memo>(serviceEndpoint, { text: newMemo }, config).pipe(
      map((results: any) => {
        return results.results;
      })
    );
  }

  public prepopulateReport(
    targetFilingId: string,
    sourceFilingId: string
  ): Observable<any> {
    const endpoint =
      ConfigService.INTEGRITY_SERVICE_FILING +
      '/' +
      targetFilingId +
      '/prepopulate';

    const requestData = {
      sourceFilingId: sourceFilingId,
      userId: this.sessionService.getMaxUsername(),
    };

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-session-user-id', this.sessionService.getMaxUsername());

    const config = {
      params: {},
      headers: headers,
    };

    return this.http.post<any>(endpoint, requestData, config).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(() => 'prepopulateReport failed')
    );
  }

  public changeFilingFilingType(
    filingId,
    newGroupSurrogateKey
  ): Observable<any> {
    // TODO
    // var defer = $q.defer();
    // if(promiseTracker) {
    //   if(angular.isArray(promiseTracker)) {
    //     promiseTracker.push(defer.promise);
    //   }
    //   else {
    //     promiseTracker.addPromise(defer.promise);
    //   }
    // }
    //
    // console.log('Rebuilding the menu in case we need to add a Compare flag');
    // $rootScope.$broadcast("rebuildNavigation");

    const endpoint = ConfigService.INTEGRITY_SERVICE_FILING_FILING_TYPE.replace(
      '{filingId}',
      filingId
    );

    const requestData = {
      groupSurrogateKey: newGroupSurrogateKey,
    };

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-session-user-id', this.sessionService.getMaxUsername());

    const config = {
      params: {},
      headers: headers,
    };

    return this.http.put<any>(endpoint, requestData, config).pipe(
      map((data: any) => {
        if (data.status == 0) {
          this.formDataPersistenceService.notifyParentWindowOfFilingChange();
          return data;
        }
      }),
      catchError(() => 'changeFilingFilingType failed')
    );
  }

  bulkActionRecordsManagementReports(
    filingIds: string[],
    action: string,
    filingData: Cell[][]
  ) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-session-user-id', this.sessionService.getMaxUsername());

    const config = {
      params: {},
      headers: headers,
    };

    const requestData = {
      filingIds: filingIds.join(','),
      action: action,
      reportsCount: filingIds.length,
    };

    if (['Purge', 'Delete'].includes(action)) {
      requestData['filingData'] = filingData;
    }

    return this.http
      .post(
        ConfigService.INTEGRITY_SERVICE_BULK_ACTION_RECORDS_MGMT_REPORTS,
        requestData,
        config
      )
      .pipe(
        map((data: any) => {
          if (data.status == 0) {
            return data;
          } else {
            throw new Error(data.statusMessage);
          }
        })
      );
  }

  getRecordsManagementBulkActionJob(jobId: string): Observable<any> {
    const endpoint = `${ConfigService.INTEGRITY_SERVICE_BULK_ACTION_RECORDS_MGMT_REPORTS}/${jobId}`;

    return this.http.get<any>(endpoint, this.getOptions()).pipe(
      map((data) => {
        if (data.status == 0) {
          return data.results;
        } else {
          throw new Error(data.statusMessage);
        }
      })
    );
  }

  cancelBulkActionRecordsManagementJob(jobId: string): Observable<any> {
    const endpoint = `${ConfigService.INTEGRITY_SERVICE_BULK_ACTION_RECORDS_MGMT_REPORTS}/${jobId}`;

    return this.http.delete<any>(endpoint, this.getOptions()).pipe(
      map((data) => {
        if (data.status == 0) {
          return data.results;
        } else {
          throw new Error(data.statusMessage);
        }
      })
    );
  }

  bulkExportReports(requestData: any): Observable<any> {
    const endpoint = `${ConfigService.INTEGRITY_SERVICE_BULK_EXPORT_REPORTS}`;

    console.log(
      'IntegrityPersistenceService.bulkExportReports endpoint -> ' + endpoint
    );

    const config = {
      params: {},
      headers: { 'x-session-user-id': this.sessionService.getMaxUsername() },
    };

    return this.http.post<any>(endpoint, requestData, config).pipe(
      map((data: any) => {
        if (data.status == 0) {
          return data;
        } else {
          throw new Error(data.statusMessage);
        }
      })
    );
  }

  getBulkExportReportsJobStatus(jobId: string): Observable<any> {
    const endpoint = `${ConfigService.INTEGRITY_SERVICE_BULK_EXPORT_REPORTS}/${jobId}`;

    console.log(
      'IntegrityPersistenceService.getBulkExportReportsJobStatus endpoint -> ' +
        endpoint
    );

    const config = {
      params: {},
      headers: { 'x-session-user-id': this.sessionService.getMaxUsername() },
    };

    return this.http.get(endpoint, config).pipe(
      map((data: any) => {
        if (data.status == 0) {
          return data.results;
        } else {
          throw new Error(data.statusMessage);
        }
      })
    );
  }

  getAnnualDataExtractStats(params: any) {
    const endpoint = ConfigService.INTEGRITY_SERVICE_ANNUAL_DATA_EXTRACT;

    if (!params.sessionUserId) {
      params.sessionUserId = this.sessionService.getMaxUsername();
    }

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-session-user-id', this.sessionService.getMaxUsername());

    const config = {
      params: {
        sessionUserId: params.sessionUserId,
      },
      headers: headers,
    };

    return this.http.post<any>(endpoint, params, config);
  }

  public bulkAssignReports(requestData: any): Observable<any> {
    const endpoint = `${ConfigService.INTEGRITY_SERVICE_BULK_ASSIGN_REPORTS}`;

    console.log(
      'IntegrityPersistenceService.bulkAssignReports endpoint -> ' + endpoint
    );

    const config = {
      params: {},
      headers: { 'x-session-user-id': this.sessionService.getMaxUsername() },
    };

    return this.http.post(endpoint, requestData, config).pipe(
      map((data: any) => {
        if (data.status === 0) {
          return data.results;
        } else {
          throw new Error(data.statusMessage);
        }
      })
    );
  }
  
  private getOptions() {
    return {
      headers: {
        'x-session-user-id': this.sessionService.getMaxUsername(),
      },
    };
  }

  /**
   * ~ v1 getDataTables
   * In v1 the only caller of this method was Management Reports so sending 'Management Reports' as a parameter
   * is not necessary.
   */
  getManagementReportsList(): Observable<any> {
    const endpoint = `${ConfigService.INTEGRITY_SERVICE_DATATABLES}`;

    console.log(
      'IntegrityPersistenceService.getDataTables endpoint -> ' + endpoint
    );

    const config = {
      params: {
        screen: 'Management Reports',
      },
      headers: { 'x-session-user-id': this.sessionService.getMaxUsername() },
    };

    return this.http.get(endpoint, config).pipe(
      map((data: any) => {
        if (data.status == 0) {
          return data.results;
        } else {
          throw new Error(data.statusMessage);
        }
      })
    );
  }
  
  public notificationsTestEmail(
    agencyId: string,
    userId: string,
    toAddress: string,
    fromAddress: string | null,
    ccAddresses: string[] | null
  ) {
    const endpoint =
      ConfigService.INTEGRITY_SERVICE_NOTIFICATIONS_TEST_EMAIL.replace(
        '{agencyId}',
        agencyId
      );

    const config = {
      params: {},
      headers: { 'x-session-user-id': this.sessionService.getMaxUsername() },
    };

    const requestData = {
      userId,
      toAddress,
      fromAddress,
      ccAddresses,
    };

    return this.http.post(endpoint, requestData, config).pipe(
      map((data: any) => {
        if (data.status == 0) {
          return data.results;
        } else {
          throw new Error(data.statusMessage);
        }
      })
    );
  }

  /**
   * Get the report data.
   * ~ getDataTablePaginatedData
   */
  public getManagementReport(params: any, table: string) {
    const me = this.sessionService.getMaxUsername();
    let serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_DATATABLES}/${table}?sessionUserId=${me}`;

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    // OGE-8386 Send in requestData, not the url
    const requestData = {agenciesAndGroups: null};
    if (params.hasOwnProperty('agenciesAndGroups')) {
      requestData.agenciesAndGroups = params.agenciesAndGroups;
      delete params.agenciesAndGroups;
    }
    
    // Append the params to the endpoint url string
    Object.keys(params).map((key) => {
      serviceEndpoint += `&${key}=${params[key]}`;
    });
      
    const config = {
      params: {},
      headers,
    };

    return this.http.post<any>(serviceEndpoint, requestData, config).pipe(
      map((res: any) => {
        return res.results;
      })
    );
  }

  public notifyFilers(
    groupId: string,
    userIds: string[],
    message: string | undefined
  ) {
    const serviceEndpoint =
      ConfigService.INTEGRITY_SERVICE_NOTIFY_FILERS.replace(
        '{groupId}',
        groupId
      );

    const requestData = {
      userIds,
      message,
    };

    const config = {
      params: {},
      headers: { 'x-session-user-id': this.sessionService.getMaxUsername() },
    };

    return this.http.post(serviceEndpoint, requestData, config).pipe(
      map((data: any) => {
        if (data.status == 0) {
          return data.results;
        } else {
          throw new Error(data.statusMessage);
        }
      })
    );
  }

  public deleteGroup(groupId: string): Observable<any> {
    const endpoint = ConfigService.INTEGRITY_SERVICE_DELETE_GROUP.replace(
      '{groupId}',
      groupId
    );

    return this.http.delete<any>(endpoint, this.getOptions()).pipe(
      map((data: any) => {
        if (data.status == 0) {
          return data.results;
        } else {
          throw new Error(data.statusMessage);
        }
      })
    );
  }

  public filerSignFiling(
    filingId: string,
    requestData: string
  ): Observable<any> {
    const serviceEndpoint: string =
      ConfigService.INTEGRITY_SERVICE_FILING_SIGN.replace(
        /{filingId}/,
        filingId
      );
    const me: string = this.sessionService.getMaxUsername();

    const config = {
      params: {},
      headers: { 'x-session-user-id': me },
    };

    const errorMsg = `filerSignFiling failed. Status != 0 {{status}} URL=${serviceEndpoint}, params=`;

    return this.http.post<any>(serviceEndpoint, requestData, config)
      .pipe(
        tap({
          next: (data: any) => {
            if (data.status !== 0) {
              LogService.log(
                'error', errorMsg.replace(/{{status}}/, data.status)
              );
              throw new Error('update-filing');
            }
          },
          error: err => {
            LogService.log('error', errorMsg.replace(/{{status}}/, ''));
            throw new Error('update-filing');
          },
        }))
      .pipe(
        catchError((error) => {
          return throwError(error);
        }),
        map((data: any) => {
          this.formDataPersistenceService.notifyParentWindowOfFilingChange(filingId);
          return Filing278.createFromJson(
            data.results.values.values.data[0][1]
          );
          return data;
      })
    );
  }
  
  public import278TTransactions(
    filingId: string,
    sourceFilingId: string
  ): Observable<any> {
    const serviceEndpoint: string =
      ConfigService.INTEGRITY_SERVICE_IMPORT_278T_TRANSACTIONS.replace(
        /{filingId}/,
        filingId
      ).replace(/{sourceFilingId}/, sourceFilingId);
    const me: string = this.sessionService.getMaxUsername();

    const config = {
      params: {},
      headers: { 'x-session-user-id': me },
    };

    return this.http.post<any>(serviceEndpoint, null, config).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(() => 'import278TTransactions failed')
    );
  }

  public getImport278TTransactionsStatus(
    filingId: string,
    sourceFilingId: string
  ): Observable<any> {
    const serviceEndpoint: string =
      ConfigService.INTEGRITY_SERVICE_IMPORT_278T_TRANSACTIONS.replace(
        /{filingId}/,
        filingId
      ).replace(/{sourceFilingId}/, sourceFilingId);
    const me: string = this.sessionService.getMaxUsername();

    const config = {
      params: {},
      headers: { 'x-session-user-id': me },
    };

    return this.http.get<any>(serviceEndpoint, config).pipe(
      map((data: any) => {
        return data.results;
      }),
      catchError(() => 'import278TTransactions failed')
    );
  }
  
  public getReviewerRecordsManagementData(requestData: any) {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_REVIEWER_RECORDS_MANAGEMENT_LIST}`;

    const config = {
      params: {},
      headers: { 'x-session-user-id': this.sessionService.getMaxUsername() },
    };

    return this.http.post(serviceEndpoint, requestData, config).pipe(
      map((res: any) => {
        return JSON.parse(res.results.result.json.data);
      })
    );
  }
  
  public getRecordsSearchResults(requestData: any) {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_REVIEWER_RECORDS_SEARCH_LIST}`;
    const config = {
      params: {},
         headers: { 'x-session-user-id': this.sessionService.getMaxUsername() },
      };
    requestData.sessionUserId = this.sessionService.getMaxUsername();
    
    return this.http.post<any>(serviceEndpoint, requestData, config)
      .pipe(
        catchError((error) => {
          return throwError(error);
        }))
      .pipe(
      map((res: any) => {
        return JSON.parse(res.results.result.json.data);
      })
    );
  }
  
  public getFindUserUnrestricted(requestData: any) {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_FIND_USER_UNRESTRICTED}`;
    const config = {
      params: {},
      headers: { 'x-session-user-id': this.sessionService.getMaxUsername() },
    };
    requestData.sessionUserId = this.sessionService.getMaxUsername();
    
    return this.http.post<any>(serviceEndpoint, requestData, config)
      .pipe(
        catchError((error) => {
          return throwError(error);
        }))
      .pipe(
        map((res: any) => {
          return JSON.parse(res.results.result.json.data);
        })
      );
  }
  
  public getReviewerQueue(requestData: any) {
    const serviceEndpoint = `${ConfigService.INTEGRITY_SERVICE_GET_REVIEWER_QUEUE}`;
    const config = {
      params: {},
      headers: { 'x-session-user-id': this.sessionService.getMaxUsername() },
    };
  
    return this.http.post<any>(serviceEndpoint, requestData, config)
      .pipe(
        catchError((error) => {
           return throwError(error);
        }))
      .pipe(
         map((res: any) => {
           const data = JSON.parse(res.results.result.json.data);
           if (!data || !data.aaData || !Array.isArray(data.aaData)) {
             LogService.log(
               'error', 'Invalid result format for getReviewerQueue'
             );
             throw new Error('get-reviewer-queue');
           }
           return data;
         })
        );
  }
  
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
