import {
  Exclude,
  plainToClassFromExist,
  Type, 
  instanceToPlain
} from 'class-transformer';
import { GroupConfigs } from './group-configs.model';
import { BaseGroup } from './base-group.model';
import { FilingTypeKey } from './filing-type-key.enum';
import { EventEmitter } from '@angular/core';
import { RoleKey } from './role.model';
import { ContactInfo } from './contact-info.model';
import { GroupType } from './group-type.enum';
import { RegularStaff } from './group-staff.model';
import { UserSelection } from '../../modules/admin/manage-agencies-and-groups/user-picker-modal/user-selection.model';

export class Group extends BaseGroup {
  isAgency: boolean = false;
  description: string;
  groupType: GroupType;
  filerCurrentFilerCategories: { [userId: string]: string } = {};
  // OGE-8539 do not auto-assign a default filing type when filer is added to the group
  filerDefaultFilingTypes: Map<string, FilingTypeKey | undefined> = new Map<
    string,
    FilingTypeKey
  >();
  filerCurrentFilerStatuses: { [userId: string]: string } = {};
  @Type(() => GroupConfigs)
  configs: GroupConfigs = new GroupConfigs();
  filingTypes: FilingTypeKey[] = [];
  @Type(() => RegularStaff)
  staff: RegularStaff;

  @Exclude() dirty: boolean = false;

  // Not true group fields. Used in some early screens before
  // the Group/Agency models were fully built out.
  // Keep until that code can be refactored
  @Exclude()
  agencyName: string;
  @Exclude()
  filerDefaultFilingType: string;

  // Events for data updates
  @Exclude()
  filingTypesUpdated$ = new EventEmitter<FilingTypeKey[]>();
  @Exclude()
  filersUpdated$ = new EventEmitter();
  @Exclude()
  filerAdded$ = new EventEmitter<{
    id: string;
    extra: UserSelection | undefined;
  }>();
  @Exclude()
  filerRemoved$ = new EventEmitter<string>();
  @Exclude()
  filersRemoved$ = new EventEmitter<string[]>();
  @Exclude()
  filerCurrentFilerCategoriesUpdated$ = new EventEmitter();
  @Exclude()
  filerDefaultFilingTypesUpdated$ = new EventEmitter();
  @Exclude()
  filerCurrentFilerStatusesUpdated$ = new EventEmitter();

  constructor() {
    super();
  }

  loadFromJson(json: any): void {
    plainToClassFromExist(this, json);
    const res = instanceToPlain(this);
    //debugger;
  }

  static createFromJson(json: any): Group {
    const group = new Group();
    group.loadFromJson(json);
    return group;
  }

  public toJSON(): any {
    let plain = instanceToPlain(this);

    // Delete any empty staff arrays
    // EFEDS-7261 If empty arrays are left in the staff object,
    // they will cause user_group_role_view to have values of user_id='[]'
    if (plain.staff) {
      const keys = Object.keys(plain.staff);
      keys.forEach((key) => {
        if (Array.isArray(plain.staff[key]) && !plain.staff[key].length) {
          delete plain.staff[key];
        }
      });
    }

    return plain;
  }

  isRegularGroup() {
    return this.groupType == GroupType.Regular;
  }

  isNomineeGroup() {
    return (
      this.groupType == GroupType.InboundNominee ||
      this.groupType == GroupType.NomineeRegular
    );
  }

  hasFilingType(filingType: FilingTypeKey): boolean {
    return this.filingTypes.includes(filingType);
  }

  addFilingType(filingType: FilingTypeKey) {
    if (!this.filingTypes.includes(filingType)) {
      this.filingTypes.push(filingType);
      this.filingTypesUpdated$.emit();
    }
  }

  removeFilingType(filingType: FilingTypeKey) {
    const idx = this.filingTypes.indexOf(filingType);
    if (idx > -1) {
      this.filingTypes.splice(idx, 1);
      this.filingTypesUpdated$.emit();
    }
  }

  getFilerDefaultFilingType(filerId: string): FilingTypeKey | undefined {
    return this.filerDefaultFilingTypes.get(filerId);
  }

  setFilerDefaultFilingType(filerId: string, filingType: FilingTypeKey) {
    this.filerDefaultFilingTypes.set(filerId, filingType);
    this.filerDefaultFilingTypesUpdated$.emit();
  }

  getFilerCurrentFilerCategory(filerId: string): string | undefined {
    return this.filerCurrentFilerCategories[filerId];
  }

  setFilerCurrentFilerCategory(filerId: string, category: string | undefined) {
    if (category) {
      this.filerCurrentFilerCategories[filerId] = category;
    } else {
      delete this.filerCurrentFilerCategories[filerId];
    }
    this.filerCurrentFilerCategoriesUpdated$.emit();
  }

  getFilerCurrentFilerStatus(filerId: string): string | undefined {
    return this.filerCurrentFilerStatuses[filerId];
  }

  setFilerCurrentFilerStatus(filerId: string, category: string | undefined) {
    if (category) {
      this.filerCurrentFilerStatuses[filerId] = category;
    } else {
      delete this.filerCurrentFilerStatuses[filerId];
    }
    this.filerCurrentFilerStatusesUpdated$.emit();
  }

  /**
   * Do not set a default filing type when adding a filer to a group unless
   * the group has only one filing type.
   * OGE-8539
   */
  addFiler(filerId: string, extra: UserSelection | undefined = undefined) {
    const added = this.staff?.addAlternate(RoleKey.Filer, filerId);
    if (added) {
      this.filersUpdated$.emit();
      this.filerAdded$.emit({ id: filerId, extra: extra });

      if (this.filingTypes.length) {
        if (this.filingTypes.length > 1) {
          this.filerDefaultFilingTypes.set(filerId, undefined);
        } else {
          this.setFilerDefaultFilingType(filerId, this.filingTypes[0]);
        }
      }
    }
  }

  removeFiler(filerId: string) {
    this.staff?.removeAlternate(RoleKey.Filer, filerId);
    this.filerDefaultFilingTypes.delete(filerId);
    delete this.filerCurrentFilerCategories[filerId];
    delete this.filerCurrentFilerStatuses[filerId];
    this.filersUpdated$.emit();
    this.filerRemoved$.emit(filerId);
    this.filerDefaultFilingTypesUpdated$.emit();
    this.filerCurrentFilerCategoriesUpdated$.emit();
    this.filerCurrentFilerStatusesUpdated$.emit();
  }

  removeFilers(filerIds: string[]) {
    filerIds.forEach((filerId) => {
      this.staff?.removeAlternate(RoleKey.Filer, filerId);
      this.filerDefaultFilingTypes.delete(filerId);
      delete this.filerCurrentFilerCategories[filerId];
      delete this.filerCurrentFilerStatuses[filerId];
    });

    this.filersUpdated$.emit();
    this.filersRemoved$.emit(filerIds);
    this.filerDefaultFilingTypesUpdated$.emit();
    this.filerCurrentFilerCategoriesUpdated$.emit();
    this.filerCurrentFilerStatusesUpdated$.emit();
  }

  transferFiler(filerId, newGroup: Group): boolean {
    if (this.staff?.hasAlternate(RoleKey.Filer, filerId)) {
      newGroup.addFiler(filerId);

      const filingType = this.filerDefaultFilingTypes.get(filerId);
      if (filingType) {
        newGroup.setFilerDefaultFilingType(filerId, filingType);
      }

      const filerCatgory = this.filerCurrentFilerCategories[filerId];
      if (filerCatgory) {
        newGroup.setFilerCurrentFilerCategory(filerId, filerCatgory);
      }

      const filerStatus = this.filerCurrentFilerStatuses[filerId];
      if (filerStatus) {
        newGroup.setFilerCurrentFilerStatus(filerId, filerStatus);
      }

      this.removeFiler(filerId);

      return true;
    }

    return false;
  }
}
