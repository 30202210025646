import { Group } from './group.model';
import { ReportStatus, Status } from './status.model';
import { v4 as uuidv4 } from 'uuid';
import { Workflow } from './workflow.model';
import { Comment, AmendmentComment } from './comment.model';
import { Signature } from './signature.model';
import { NomineeContext } from './nominee-context.model';
import { Util } from '../util';
import { isAccessor } from 'typescript';
import { DateTime } from 'luxon';
import { Prepopulation } from './prepopulation.model';
import _ from 'lodash-es';

class FilingMap {
  static readonly map: Map<string, string> = new Map<string, string>([
    ['report_positionTitle', 'position'],
    ['report_agencyName', 'agency'],
    ['report_filerCategory', ''],
    ['report_groupName', 'group'],
  ]);
}

export interface ReviewerRejection {
  by: string;
  roleSurrogateKey: string;
}

export interface ReportClosed {
  name: string;
  date: number;
}

export class Filing {
  // Not passed to Node
  private static NOT_PASSED = [
    'agency',
    'createdAt',
    'filerName',
    'status',
    'hidden',
    '_originalDue',
    '_extension',
    'url', // This property is getting added in FilingHelper and must be omitted from the json sent to save
    'version',
    'extensionRequest', 
    'allowExtensionRequest',
    'extensionRequestLabel',
  ];
  // Required
  year: number;
  item: string;
  type: string;
  filingId: string;
  filingType: string;
  userId: string;
  workflowStep: number;
  // Optional
  position?: string;
  termination?: string;  // used by MAR and BAR for assignment create/update; remove properties in node before saving the filing
  appointment?: string;  // same as above
  _originalDue?: Date; // see get/set methods
  due?: Date | undefined;
  addedTime?: number;
  assignedTo?: string | null = null;
  originalUserId?: string;
  publicAvailability?: string;
  publicAvailabilityPost?: string;
  purged = false;
  retained = false;
  expired?: string;
  dueComments?: string;
  _extension?: string; // see get/set methods
  extensionRequest: string;
  extensionRequestLabel: string; // for My Tasks
  allowExtensionRequest: boolean;
  extensionComments?: string;
  combatZoneExtension: boolean;
  assignNotify?: string;
  notifyMessage?: string;
  notified?: string;
  workflow: Workflow;
  workflowStepRole: string;
  filerCertified: boolean;
  certifyTime: number;
  filingDate: string;
  navHighwaterIndex: number;
  allowCompare: boolean;
  filerCategory?: string;
  publicAvailablityPost?: string;
  agencyReviewer?: string;
  ogeReviewer?: string;
  lateFeePaid: boolean;
  lateFeeWaiver?: string;
  lateFeeRequired: boolean;
  endinitialReview?: string;
  statusComments?: string;
  filerStatus?: string;
  daeo: boolean;
  amendment?: string;
  amendmentComments?: string;
  amendmentPublicAnnotation: AmendmentComment | undefined = undefined;
  agencyCertificationCloseDate: number;
  ogeCertificationCloseDate: number;
  workflowResetHistory: any[] = [];
  filingResetHistory: any[] = [];
  memo: any; // TODO
  comments: Comment[];
  deletedComments: Comment[] = [];
  publicAnnotations: Comment[] = [];
  deletedPublicAnnotations: Comment[] = [];
  signatures: Map<string, Signature[]> = new Map();
  reviewerRejectionStack: ReviewerRejection[] = [];
  assignedToRoleSurrogateKey: string | undefined = undefined;
  filerCertifiedDoD = false;
  amendNotify = false;
  amendNotifyCc: string[] | undefined = undefined;
  amendMessage: string | undefined = undefined;
  reassignMessage: string | undefined = undefined;
  reassignNotifyFlag: boolean | undefined = undefined;
  reassignNotifyCc: string[] | undefined = undefined;
  rulesVersion: number | undefined = undefined;
  version: string;
  source_layer = 'node';
  session_user_id: string | undefined = undefined;
  serverTimestamp: number | undefined = undefined;
  _transientColumns: Map<string, any> | undefined = undefined;
  wizardCompletedPositions: string[] | undefined;
  group: Group;
  agency: string;
  createdAt: Date; // TODO: Should this be created_at?
  created_at: string; // Added for EFEDS-6253 (see prepopulate.component.html)
  filerName: string;
  status: Status;
  hidden: boolean;
  prepopulated: string;
  prepopulation: Prepopulation;
  nomineeUpdate: any;
  noNonFedPositionsFlag: boolean;
  skipRetirementWizard: boolean;
  nomineeAgencies: any[];

  static createNew(
    group: Group,
    position: string,
    filingOwnerId: string
  ): Filing {
    const report = new Filing();

    const due = new Date();
    const addedTime = new Date();
    due.setDate(due.getDate() + 30);
    report.year = new Date().getFullYear();
    report.item = 'Periodic Transaction Report';
    report.position = position;
    report.status = new Status(ReportStatus.Draft);
    report.filingId = uuidv4().toUpperCase();
    report.addedTime = addedTime.getTime();
    report.group = group;
    report.agency = group.agencyName;
    report.originalDue = due;
    report.due = due;
    report.assignedTo = filingOwnerId;
    report.userId = filingOwnerId;
    report.filingType = group.filerDefaultFilingType;
    report.workflowStep = 1;
    const status = new Status(ReportStatus.Draft);
    status.by = filingOwnerId;
    status.timeStamp = addedTime.getTime();
    report.workflow = new Workflow(status);
    report.workflow.history = [];
    report.version = '1';

    return report;
  }

  loadFromJson(item: any): void {
    const group = new Group();
    group.name = !!item.group ? item.group : '';
    group.surrogateKey = !!item.data.groupSurrogateKey
      ? item.data.groupSurrogateKey
      : '';
    group.filerDefaultFilingType = !!item.filer_default_filing_type
      ? item.filer_default_filing_type
      : '';

    this.group = group;
    this.agency = item.agency;
    this.certifyTime = item.data.certifyTime;
    this.createdAt = new Date(item.created_at);
    this.due = Util.convertStringToDate(item.data.due);
    if (!!this.due && isNaN(this.due.getTime())) {
      this.due = undefined;
    }
    this.originalDue = Util.convertStringToDate(item.data.originalDue);
    this.dueComments = item.data.dueComments;
    this.extension = item.data.extension;
    this.extensionComments = item.data.extensionComments;
    this.combatZoneExtension = item.data.combatZoneExtension;
    this.filerName = item.filer_name;
    this.userId = item.filer_user_id;
    this.originalUserId = item.data.originalUserId;
    this.item = item.data.item;
    this.position = item.position ?? item.data.position;
    this.appointment = item.data.appointment; // used by MAR and BAR for assignment create/update; remove properties in node before saving the filing
    this.termination = item.data.termination; // same as above

    if (!!item.data.workflow && !!item.data.workflow.current) {
      this.status = new Status(item.data.workflow.current.status);
    }

    this.type = item.data.type;

    if (!!item.data.workflow) {
      this.workflow = Workflow.createFromJson(
        item.data.workflow,
        item.certified === 'true'
      );
    }
    this.year = parseInt(item.data.year, 10);

    this.addedTime = item.data.addedTime;
    this.agencyCertificationCloseDate = item.data.agencyCertificationCloseDate;
    this.agencyReviewer = item.data.agencyReviewer;
    this.amendment = item.data.amendment;
    this.amendmentComments = item.data.amendmentComments;
    this.amendmentPublicAnnotation = AmendmentComment.createFromJson(
      item.data.amendmentPublicAnnotation
    );
    this.assignedTo = item.data.assignedTo;
    this.certifyTime = item.data.certifyTime;
    this.comments = Comment.createArrayFromJson(item.data.comments);
    if ('deletedComments' in item.data) {
      this.deletedComments = Comment.createArrayFromJson(
        item.data.deletedComments
      );
    } else {
      this.deletedComments = [];
    }
    this.filerCertified = item.data.filerCertified;
    this.filerStatus = item.data.filerStatus;
    this.filingDate = item.data.filingDate;
    this.filingId = item.data.filingId;
    this.filingType = item.data.filingType;
    this.item = item.data.item;
    this.navHighwaterIndex = item.data.navHighwaterIndex;
    this.ogeCertificationCloseDate = item.data.ogeCertificationCloseDate;
    this.ogeReviewer = item.data.ogeReviewer;
    this.publicAnnotations = Comment.createArrayFromJson(
      item.data.publicAnnotations
    );
    if ('deletedPublicAnnotations' in item.data) {
      this.deletedPublicAnnotations = Comment.createArrayFromJson(
        item.data.deletedPublicAnnotations
      );
    } else {
      this.deletedPublicAnnotations = [];
    }
    this.publicAvailability = item.data.publicAvailability;
    this.publicAvailabilityPost = item.data.publicAvailabilityPost;
    this.purged = item.data.purged;
    this.retained = item.data.retained;
    this.expired = item.data.expired;
    this.serverTimestamp = item.data.server_timestamp;
    this.session_user_id = item.data.session_user_id;
    this.type = item.data.type;
    this.userId = item.data.userId;
    this.workflowStep = item.data.workflowStep;
    this.prepopulated = item.data.prepopulated;
    if ('prepopulation' in item.data) {
      this.prepopulation = item.data.prepopulation;
    }

    this.signatures = Signature.createMapFromJson(item.data.signatures);
    this.assignedToRoleSurrogateKey =
      item.data?.assignedToRoleSurrogateKey || this.assignedToRoleSurrogateKey;
    this.reviewerRejectionStack =
      item.data?.reviewerRejectionStack || this.reviewerRejectionStack;
    this.memo = item.data.memo;
    if (item.data.hasOwnProperty('rulesVersion')) {
      this.rulesVersion = item.data.rulesVersion;
    }
    if (item.data.hasOwnProperty('reassignNotifyFlag')) {
      this.reassignNotifyFlag = item.data.reassignNotifyFlag;
    }
    this.assignNotify = item.data.assignNotify;
    if (item.data.hasOwnProperty('notifyMessage')) {
      this.notifyMessage = item.data.notifyMessage;
    }
    if (item.data.hasOwnProperty('notified')) {
      this.notified = item.data.notified;
    }
    this.allowCompare = item.data.allowCompare;
    this.lateFeePaid = item.data.lateFeePaid !== null ? item.data.lateFeePaid : undefined;
    this.lateFeeRequired = item.data.lateFeeRequired !== null ? item.data.lateFeeRequired : undefined;
    this.lateFeeWaiver = item.data.lateFeeWaiver;
    this.daeo = item.data.daeo;
    this.filerCertifiedDoD = item.data.filerCertifiedDoD;
    this.endinitialReview = item.data.endinitialReview;
    this.statusComments = item.data.statusComments;
    this.workflowResetHistory = item.data.workflowResetHistory;
    this.filingResetHistory = item.data.filingResetHistory;
    this.version = item.version;
  }

  static createFromJson(item: any): Filing {
    const report = new Filing();

    report.loadFromJson(item);

    if (item.data.hasOwnProperty('rulesVersion')) {
      report.rulesVersion = item.data.rulesVersion;
    }

    report.signatures = new Map<string, Signature[]>();
    for (const prop in item.data.signatures) {
      if (item.data.signatures.hasOwnProperty(prop)) {
        report.signatures.set(prop, item.data.signatures[prop]);
      }
    }

    return report;
  }

  set originalDue(value) {
    this._originalDue = value;
    if (value) {
      this.calculateDueDateWithExtension('_originalDue', 'due');
    }
  }

  get originalDue() {
    return this._originalDue;
  }

  set extension(value) {
    this._extension = value;
    if (value) {
      this.calculateDueDateWithExtension('_originalDue', 'due');
    }
  }

  get extension() {
    return this._extension ? '' + this._extension : undefined;
  }

  calculateDueDateWithExtension(original: string, calculated: string) {
    if (this[original] != null) {
      let due = new Date(this[original]);
      const extension =
        this._extension && /^[0-9]{1,5}$/.test(this._extension)
          ? parseInt(this._extension)
          : 0;
      due.setDate(due.getDate() + extension);
      this[calculated] = due;
    }
  }
  
  /**
   * Upgrading angular sort:
   * https://www.digitalocean.com/community/tutorials/how-to-upgrade-angular-sorting-filters
   *
   * @param property
   * @param sortReverse
   */
  static dynamicSort(
    property: string,
    sortReverse: boolean
  ): (a: Filing, b: Filing) => number {
    const sortOrder = sortReverse ? -1 : 1;

    return (a: Filing, b: Filing) => {
      let result = 0;
      switch (property) {
        case 'created_at': {
          const created_at_a = DateTime.fromISO(a.created_at).toMillis();
          const created_at_b = DateTime.fromISO(b.created_at).toMillis();
          result =
            created_at_a < created_at_b
              ? -1
              : created_at_a > created_at_b
              ? 1
              : 0;
          break;
        }
        case 'status': {
          result =
            a.status.status < b.status.status
              ? -1
              : a.status.status > b.status.status
              ? 1
              : 0;
          break;
        }
        case 'group': {
          if (!a.group && !!b.group) {
            result = -1;
          } else if (!b.group && !!a.group) {
            result = 1;
          } else if (!!a.group && !!b.group) {
            result =
              a.group.name < b.group.name
                ? -1
                : a.group.name > b.group.name
                ? 1
                : 0;
          }
          break;
        }
        // case 'certifyTime': {
        //   if (!a.certifyTime && !!b.due) {
        //     result = -1;
        //   } else if (!b.due && !!a.due) {
        //     result = 1;
        //   } else if (!!a.due && !!b.due) {
        //     result = (a.due < b.due) ? -1 : (a.due > b.due) ? 1 : 0;
        //   }
        //   break;
        // }
        default: {
          if (!(a as any)[property] && !!(b as any)[property]) {
            result = -1;
          } else if (!(b as any)[property] && !!(a as any)[property]) {
            result = 1;
          } else if (!!(a as any)[property] && !!(b as any)[property]) {
            result =
              (a as any)[property] < (b as any)[property]
                ? -1
                : (a as any)[property] > (b as any)[property]
                ? 1
                : 0;
          }
        }
      }

      return result * sortOrder;
    };
  }

  /**
   * EFEDS-6254, EFEDS-6314 when we have a General Information page, we will need to add that path here.
   */
  getUrl(): string {
    const formId: string = this.getFilingFormId();

    if (this.status.status === ReportStatus.DataImported) {
      return `reviewer/filing/${this.filingId}/general-information`;
    }

    // This is temporary to allow access to the review report view.
    // The full set of URL mappings still needs to be pulled over.
    if (this.isNomineeReport()) {
      if (
        [
          ReportStatus.AgencyReview,
          ReportStatus.UnderOGEReview,
          ReportStatus.DraftPendingRelease,
          ReportStatus.DraftUnderReview,
          ReportStatus.DraftPreCleared,
        ].includes(this.status.status)
      ) {
        return `reviewer/filing/${this.filingId}/general-information`;
      }
    } else {
      if (
        ![ReportStatus.NotStarted, ReportStatus.Draft].includes(
          this.status.status
        )
      ) {
        return `reviewer/filing/${this.filingId}/general-information`;
      }
    }

    switch (formId) {
      case '2_1_My_Filing':
        return `filer/filing/${this.filingId}/getting-started`;

      case '278_0_T_Cover_Page':
        return `filer/filing/${this.filingId}/wizard/${this.getFilingFormId()}`;

      default:
        return `filer/filing/${this.filingId}/wizard/${this.getFilingFormId()}`;
    }
  }

  is278t(): boolean {
    return !!(
      (this.type && this.type === '278-T') ||
      (this.item && this.item.indexOf('278-T') === 0)
    );
  }

  hasPrepopulateFilings(filingId: string) {
    return this.getPrepopulateFilings(filingId).length > 0;
  }

  getPrepopulateFilings(filingId: string) {
    const filings = localStorage.getItem('filings');
    if (filings != null) {
      return JSON.parse(filings).filter(
        (filing) => filing.type === '278' && filing.data.filingId !== filingId
      );
    }
  }

  getIndicativeDataFormId() {
    if (this.is278t()) {
      return '278_0_T_Cover_Page';
    } else {
      return '2_1_My_Filing';
    }
  }

  isPasDaeo(): boolean {
    return this.filingType === 'CONFIRMED_278';
  }

  isNomineeReport(): boolean {
    return this.item === 'Nominee Report';
  }

  isNewEntrantReport(): boolean {
    return this.item === 'New Entrant Report';
  }

  isAnnualReport(): boolean {
    return this.item === 'Annual Report';
  }

  isTerminationReport(): boolean {
    return this.item === 'Termination Report';
  }

  isAnnualTerminationOrTerminationReport(): boolean {
    return (
      this.item === 'Annual/Termination Report' ||
      this.item === 'Termination Report'
    );
  }

  isAnnualTerminationReport(): boolean {
    return this.item === 'Annual/Termination Report';
  }

  isNotAnnualOrTermination(): boolean {
    return !(
      this.isAnnualReport() ||
      this.isTerminationReport() ||
      this.isAnnualTerminationReport()
    );
  }

  /**
   * ~ isATC
   */
  isAnnualOrTermination(): boolean {
    return (
      this.isAnnualReport() ||
      this.isTerminationReport() ||
      this.isAnnualTerminationReport()
    );
  }

  /**
   * ~ isNN
   */
  isNewEntrantOrNominee(): boolean {
    return this.isNewEntrantReport() || this.isNomineeReport() ? true : false;
  }

  setCurrentStatus(currentStatus: Status) {
    // create initial workflow object if it doesn't exist
    if (!this.workflow) {
      this.workflow = new Workflow(currentStatus);
    }

    // archive the current status
    if (!!this.workflow.status) {
      this.workflow.history.push(this.workflow.status);
    }

    // set the new status as current
    this.workflow.status = currentStatus;
  }

  toJson(): any {
    let json: any = {};
    const getters = ['originalDue', 'extension'];
    const props = getters.concat(Object.keys(this));
    for (const prop of props) {
      switch (prop) {
        case 'year': {
          json.year = !!this.year ? '' + this.year : '';
          break;
        }
        case 'comments': {
          json.comments = this.comments.map((comment) => comment.toJson());
          break;
        }
        case 'publicAnnotations': {
          json.publicAnnotations = this.publicAnnotations.map((comment) =>
            comment.toJson()
          );
          break;
        }
        case 'amendmentPublicAnnotation': {
          json.amendmentPublicAnnotation = !!this.amendmentPublicAnnotation
            ? this.amendmentPublicAnnotation.toJson()
            : undefined;
          break;
        }
        case 'workflow': {
          json.workflow = this.workflow.toJson();
          break;
        }
        case 'group': {
          json.groupSurrogateKey =
            this.group?.surrogateKey != '' ? this.group?.surrogateKey : null;
          json.filingType = this.group?.filerDefaultFilingType;
          break;
        }
        case 'serverTimestamp': {
          json.server_timestamp = this.serverTimestamp;
          break;
        }
        case 'addedTime': {
          if (this.addedTime === -999) {
            json.addedTime = '{{payload.data[0][1].data.server_timestamp}}';
          } else {
            json.addedTime = this[prop];
          }
          break;
        }
        case 'signatures': {
          const signatures = {};
          this.signatures.forEach((value: Signature[], key: string) => {
            signatures[key] = value.map((signature) => {
              return Signature.createFromJson(signature).toJSON();
            });
            // signatures[key] = value;
          });
          json.signatures = signatures;
          break;
        }
        case 'nomineeContext': {
          if (
            !this.is278t() &&
            !!(this as any)[prop] &&
            (this as any)[prop] !== undefined &&
            Object.keys((this as any)[prop]).length
          ) {
            json.nomineeContext = this[prop].toJSON();
          }
          break;
        }
        case 'due':
        case 'originalDue':
        case 'extension':
        default: {
          if (!Filing.NOT_PASSED.includes(prop)) {
            if ((this as any)[prop] instanceof Date) {
              json[prop] = !!(this as any)[prop]
                ? Util.convertDateToZeroPadString((this as any)[prop])
                : '';
            } else if ((this as any)[prop] instanceof Map) {
              // Convert maps to plain JS objects
              json[prop] = {};
              for (const key of this[prop].keys()) {
                json[prop][key] = this[prop].get(key);
              }
            } else {
              json[prop] = (this as any)[prop];
            }
          }
        }
      }
    }
    // Remove undefined properties
    json = _.omit(
      json,
      _.filter(_.keys(json), function (key) {
        return _.isUndefined(json[key]);
      })
    );
    return json;
  }

  private mapReplacer(key, value) {
    const originalObject = this[key];
    if (originalObject instanceof Map) {
      let res = {};
      let keys = originalObject.keys();
      let key = keys.next();
      while (!key.done) {
        res[key.value] = originalObject.get(key.value);
        key = keys.next();
      }
      return res;
    } else {
      return value;
    }
  }

  private mapReviver(key, value) {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  }

  private getFilingFormId(): string {
    switch (this.type) {
      case '278-T':
        return '278_0_T_Cover_Page';
      default:
        return '2_1_My_Filing';
    }
  }

  update(updatedProps: string[], submittedValues: any): boolean {
    let updated = false;
    updatedProps.forEach((prop) => {
      const value = submittedValues[prop];
      const mappedProp = FilingMap.map.get(prop);
      if (!!mappedProp) {
        (this as any)[mappedProp] = value;
        updated = true;
      }
    });

    return updated;
  }

  addTransientColumn(column: string, value: any) {
    if (!this._transientColumns) {
      this._transientColumns = new Map();
    }

    this._transientColumns.set(column, value);
  }

  addSignature(role: string, signature: Signature) {
    role = role.toUpperCase();

    let list;

    if (!this.signatures.has(role)) {
      list = [];
      this.signatures.set(role, list);
    } else {
      list = this.signatures.get(role);
    }
    list.push(signature);
  }

  getWorkflowStep(workflow: Workflow[]): Workflow {
    for (let i = 0; i < workflow.length; i++) {
      if (workflow[i].step == this.workflowStep) {
        return workflow[i];
      }
    }

    throw new Error(`Step ${this.workflowStep} not found in workflow`);
  }

  addReviewerRejection(by: string, roleSurrogateKey: string): void {
    if (!by || !roleSurrogateKey) {
      return;
    }

    const rejection: ReviewerRejection = {
      by: by,
      roleSurrogateKey: roleSurrogateKey,
    };

    this.reviewerRejectionStack.push(rejection);
  }

  get filerCategoryOptionName(): string {
    let optionName = 'Filer Category';

    if (this.rulesVersion && this.rulesVersion >= 201902) {
      optionName += ' v201902';
    }

    if (this.isNomineeReport()) {
      return (optionName += ' Nominee');
    }

    return optionName;
  }
  
  setVersion(version: string) {
    if (!this.version || (!isNaN(parseInt(version, 10)) && parseInt(version, 10) > parseInt(this.version, 10))) {
      this.version = version;
    }
  }
} // end Filing

export class Filing278 extends Filing {
  wizardCompletedPositions: string[] = [];
  skipRetirementWizard = false;
  noFederalPositionsFlag = false;
  noEmploymentAssetsFlag = false;
  noOtherAssetsFlag = false;
  noSpouseAssetsFlag = false;
  noGiftsFlag = false;
  noNonFedPositionsFlag = false;
  noArrangementsFlag = false;
  noCompensationFlag = false;
  noLiabilitiesFlag = false;
  noTransactionsFlag = false;
  nomineeContext?: NomineeContext;
  nomineeAgencies: string[] = [];
  nominated?: Date | undefined;
  sentToSenate?: Date | undefined;
  confirmed?: Date | undefined;
  recessAppointment?: Date | undefined;
  withdrawn?: Date | undefined;
  nomineeSenateCommittees: string[] = [];
  assigningGroupSurrogateKey?: string;
  nomineeUpdate = false;

  loadFromJson(item: any) {
    super.loadFromJson(item);
    this.prepopulated = item.data.prepopulated;
    this.wizardCompletedPositions = item.data.wizardCompletedPositions;
    this.skipRetirementWizard = item.data.skipRetirementWizard;
    this.noFederalPositionsFlag = item.data.noFederalPositionsFlag;
    this.noEmploymentAssetsFlag = item.data.noEmploymentAssetsFlag;
    this.noOtherAssetsFlag = item.data.noOtherAssetsFlag;
    this.noSpouseAssetsFlag = item.data.noSpouseAssetsFlag;
    this.noGiftsFlag = item.data.noGiftsFlag;
    this.noNonFedPositionsFlag = item.data.noNonFedPositionsFlag;
    this.noArrangementsFlag = item.data.noArrangementsFlag;
    this.noCompensationFlag = item.data.noCompensationFlag;
    this.noLiabilitiesFlag = item.data.noLiabilitiesFlag;
    this.noTransactionsFlag = item.data.noTransactionsFlag;
    this.prepopulation = item.data.prepopulation;
    if (this.isNomineeReport() && !!item.data.nomineeContext) {
      this.nomineeContext = NomineeContext.createFromJson(
        item.data.nomineeContext
      );
    }
    this.nomineeAgencies = item.data.nomineeAgencies;
    this.nominated = Util.convertStringToDate(item.data.nominated);
    this.sentToSenate = Util.convertStringToDate(item.data.sentToSenate);
    this.confirmed = Util.convertStringToDate(item.data.confirmed);
    this.recessAppointment = Util.convertStringToDate(
      item.data.recessAppointment
    );
    this.withdrawn = Util.convertStringToDate(item.data.withdrawn);
    this.nomineeSenateCommittees = item.data.nomineeSenateCommittees;
    this.assigningGroupSurrogateKey = item.data.assigningGroupSurrogateKey;
    this.nomineeUpdate = item.data.nomineeUpdate;
    this.extensionRequest = !!item.extensionRequest ? item.extensionRequest : undefined;
    this.extensionRequestLabel = !!item.extensionRequestLabel ? item.extensionRequestLabel : undefined;
    this.allowExtensionRequest = item.allowExtensionRequest === 'true';
  }

  static createFromJson(data: any): Filing278 {
    const report = data.type == '278-T' ? new Filing278T() : new Filing278();
    report.loadFromJson(data);
    return report;
  }

  isPpoWorkflow(): boolean {
    return (
      !!this.nomineeContext &&
      this.nomineeContext.workflow.name.includes('_PPO_')
    );
  }

  isPttWorkflow(): boolean {
    return (
      !!this.nomineeContext &&
      this.nomineeContext.workflow.name.includes('_PTT_')
    );
  }

  isWhcoWorkflow(): boolean {
    return (
      !!this.nomineeContext &&
      this.nomineeContext.workflow.name.includes('_WHCO_')
    );
  }

  get terminalStepTimestamp(): number | undefined {
    if (this.status.isTerminal) {
      return this.workflow.status.timeStamp;
    }
    return undefined;
  }
}

export class Filing278T extends Filing278 {
  originalTransactionDue?: string;
  transactionDue?: string;

  constructor() {
    super();
    this.type = '278-T';
  }

  get extension() {
    return super.extension;
  }

  set extension(value) {
    super.extension = value;

    if (value) {
      this.calculateDueDateWithExtension(
        'originalTransactionDue',
        'transactionDue'
      );
    }
  }

  static createNew(
    group: Group,
    position: string,
    filingOwnerId: string
  ): Filing278T {
    const report = super.createNew(
      group,
      position,
      filingOwnerId
    ) as Filing278T;
    report.type = '278-T';
    return report;
  }

  loadFromJson(item: any) {
    super.loadFromJson(item);
    this.transactionDue = item.data.transactionDue;
    this.originalTransactionDue = item.data.originalTransactionDue;
  }
}

export class FilingHelper extends Filing {
  data: {
    filingId: string;
    prepopulation: Prepopulation;
  };
  filingId: string;
  url: string;
  certified: string;
  checked: boolean = false;
}
